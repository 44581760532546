import React, { createContext, useContext, useState, useLayoutEffect } from 'react';

import { ThemeProvider as ThemeProviderMaterial, Theme as ThemeMaterial } from '@material-ui/core/styles';
import { ThemeProvider, DefaultTheme } from 'styled-components';

import { GlobalStyle } from './global';
import { themes } from './themes';

type Theme = {
  styledComponent: DefaultTheme;
  material: ThemeMaterial;
};

type StateType = {
  mounted?: boolean;
  theme: Theme;
};

type ThemeState = {
  mounted?: boolean;
  themeName: 'light' | 'dark';
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeState>({
  themeName: themes.light.styledComponent.title,
  toggleTheme: () => {
    return;
  },
});

const ThemeGlobalProvider = ({ children }: any) => {
  const [state, setState] = useState<StateType>({ theme: themes.dark });

  const toggleTheme = () => setState(state.theme.styledComponent.title === 'light' ? newTheme(themes.dark) : newTheme(themes.light));

  useLayoutEffect(() => newStateMounted(setState, state.mounted), [state]);

  return (
    <ThemeContext.Provider value={{ toggleTheme, themeName: state.theme.styledComponent.title, mounted: state.mounted }}>
      <ThemeProvider theme={state.theme.styledComponent}>
        <GlobalStyle />
        <ThemeProviderMaterial theme={state.theme.material}>{children}</ThemeProviderMaterial>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const newTheme = (theme: Theme) => ({ theme, mounted: false });
const newStateMounted = (setState: React.Dispatch<React.SetStateAction<StateType>>, mounted?: boolean) => {
  if (!mounted) setState((prev) => ({ ...prev, mounted: true }));
};

const useTheme = () => {
  const { themeName, toggleTheme, mounted } = useContext<ThemeState>(ThemeContext);

  return {
    themeMounted: mounted,
    theme: themeName,
    toggleTheme,
  };
};

export { ThemeGlobalProvider, useTheme };
