import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { bindActionCreators, Dispatch } from "redux";

import { LoadingSpin, Error } from "../../../components";
import { ROUTES } from "../../../constants/routesConstants";
import { Blog, PageContent, PageLanguage, Post } from "../../../constants/types";
import Api from "../../../services/api";
import { ApplicationState } from "../../../store";
import { loadRequest } from "../../../store/ducks/blog/actions";
import BrowserUtils from "../../../Utils/BrowserUtils";
import ListUtils from "../../../Utils/ListUtils";
import RouterUtils from "../../../Utils/RouterUtils";
import StoreUtils from "../../../Utils/StoreUtils";
import StringUtils from "../../../Utils/StringUtils";

import BlogView from "./BlogView";

type StateProps = {
  blogData: Array<Blog>;
  pageLanguage: PageLanguage;
  blogLoading: boolean;
  blogError: boolean;
};

type DispatchProps = {
  loadRequest(pageLanguage: PageLanguage): void;
};

type OwnProps = {
  className?: string;
  homepage?: boolean;
  postId?: string;
};

export type PostState = {
  data?: Post;
  loading: boolean;
  error: boolean;
};

type State = {
  posts: {
    [language: string]: Array<Post>;
  };
  postIdSelected: string;
  post: PostState;
  dialogOpen: boolean;
};

type Props = StateProps & DispatchProps & OwnProps;

const BlogPage: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState(initialState(props.postId || ""));

  const blogDataIsEmpty = StoreUtils.StateIsEmpty({ data: props.blogData, error: props.blogError, loading: props.blogLoading });

  const startWithPostFromUrl = state.postIdSelected === props.postId && !blogDataIsEmpty;

  const closeDialog = () => setState((prev) => ({ ...prev, dialogOpen: false }));

  const loadPostSelectedByID = (postId: string) => loadPostByID(postId, props, state, setState);

  const loadPost = (post: Post) => {
    let postSelectedByPageLanguage;

    props.blogData.find((p) => {
      postSelectedByPageLanguage = p.posts.find((p) => p.id === post.id);

      return !!postSelectedByPageLanguage;
    });

    loadPostRequested(postSelectedByPageLanguage || post, props.pageLanguage, state, setState);
  };

  /**
   * Effect to change language of posts which was open
   */
  useEffect(() => {
    if (state.dialogOpen && !blogDataIsEmpty && state.post.data) loadPost(state.post.data);
  }, [props.pageLanguage, props.blogData]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect to monitoring the dialog and replace Browser Url in regards to new post selected if dialog is true
   * When Dialog is false, the browser is replaced to blog root path.
   */
  useEffect(() => {
    if (state.dialogOpen) BrowserUtils.replaceUrlBrowser(RouterUtils.getRouteBlogPostWithId(state.postIdSelected).path);
    else BrowserUtils.replaceUrlBrowser(ROUTES.BLOG.path);
  }, [state.dialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (blogDataIsEmpty) props.loadRequest(props.pageLanguage);

  if (props.blogError) return <Error onClickTryAgain={() => props.loadRequest(props.pageLanguage)} />;
  else if (props.blogLoading || blogDataIsEmpty) return <LoadingSpin />;
  else if (startWithPostFromUrl) loadPostSelectedByID(state.postIdSelected);

  return (
    <BlogView
      blogData={props.blogData}
      pageLanguage={props.pageLanguage}
      handleClickInPost={loadPost}
      closeDialog={closeDialog}
      isDialogOpen={state.dialogOpen}
      post={state.post}
    />
  );
};

const initialState = (postId: string): State => ({
  posts: {},
  postIdSelected: postId,
  post: { loading: false, error: false },
  dialogOpen: StringUtils.isNotNullOrEmpty(postId),
});

const mapStateToProps = (state: ApplicationState) => ({
  blogData: state.blog.data[state.pageLanguage],
  blogLoading: state.blog.loading,
  blogError: state.blog.error,
  pageLanguage: state.pageLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage);

/**
 * Method responsible for loading selected post by id from URL
 *
 * @param {string} postId: post id to load
 * @param {Props} props: component props
 * @param {State} state: component state
 * @param {React.Dispatch<React.SetStateAction<State>>} setState: function to update component state
 */
const loadPostByID = (postId: string, props: Props, state: State, setState: React.Dispatch<React.SetStateAction<State>>) => {
  const thereNoPostSelectedYet = !state.post.data && StringUtils.isNotNullOrEmpty(postId);

  if (thereNoPostSelectedYet) {
    let postSelected;

    props.blogData.find((b) => {
      postSelected = b.posts.find((p) => p.id === postId);
      return !!postSelected;
    });

    if (postSelected) loadPostRequested(postSelected, props.pageLanguage, state, setState);
  }
};

/**
 *
 * Method responsible for loading selected post in component state or via external api if post does not exist in memory
 *
 * @param {Post} post: post selected
 * @param {PageLanguage} pageLanguage: post selected language
 * @param {State} state: component state
 * @param {React.Dispatch<React.SetStateAction<State>>} setState: function to update component state
 */
const loadPostRequested = (post: Post, pageLanguage: PageLanguage, state: State, setState: React.Dispatch<React.SetStateAction<State>>) => {
  if (post.isExternalPost) window.open(post.externalLink, "_blank");
  else {
    const postFounded = state.posts[pageLanguage] ? state.posts[pageLanguage].find((p) => p.id === post.id) : null;

    if (postFounded) {
      setState((prev) => ({
        ...prev,
        dialogOpen: true,
        postIdSelected: postFounded.id,
        post: { data: postFounded, loading: false, error: false },
      }));
    } else {
      getPostContent(post, pageLanguage, state, setState);
    }
  }
};

/**
 *  * Method responsible for loading post content from via external api
 *
 * @param {Post} post: post selected
 * @param {PageLanguage} pageLanguage: post selected language
 * @param {State} state: component state
 * @param {React.Dispatch<React.SetStateAction<State>>} setState: function to update component state
 */
const getPostContent = (post: Post, pageLanguage: PageLanguage, state: State, setState: React.Dispatch<React.SetStateAction<State>>) => {
  setState((prev) => ({ ...prev, dialogOpen: true, postIdSelected: post.id, post: { ...prev.post, data: post } }));

  if (!state.post.loading || state.post.error) {
    setState((prev) => ({ ...prev, postIdSelected: post.id, post: { data: post, error: false, loading: true } }));
  }

  const buildNewPosts = (oldPosts: Array<Post>, newPost: Post) => (ListUtils.isNullOrEmpty(oldPosts) ? [newPost] : [...oldPosts, newPost]);

  const buildStateForSuccessRequest = (prev: State, content: Array<PageContent>): State => {
    const newPost: Post = { ...post, content };
    return {
      ...prev,
      postIdSelected: newPost.id,
      post: { ...prev.post, error: false, loading: false, data: newPost },
      posts: { ...prev.posts, [pageLanguage]: buildNewPosts(prev.posts[pageLanguage], newPost) },
    };
  };

  Api.getPost<Array<PageContent>>(pageLanguage, post.id)
    .then((content) => setState((prev) => buildStateForSuccessRequest(prev, content)))
    .catch(() => setState((prev) => ({ ...prev, error: true, loading: false })));
};
