import React from "react";

import { Tooltip } from "@material-ui/core";

import { AppTranslations } from "../../../assets/translations";
import { PageLanguage, Technology as TechnologyType } from "../../../constants/types";
import { SubTopic as Tags, SubtitleSmall as TechName } from "../../Typography";
import { ContainerTechnology, TooltipStyleClass } from "../styles";

import Slash from "./Slash";

type Props = {
  children?: never;
  navigate: (path: string) => void;
  pageLanguage: PageLanguage;
  technology: TechnologyType;
  totalProjects: number;
};

export const Technology: React.FC<Props> = ({ technology, totalProjects, navigate, pageLanguage }) => {
  const tagsString = technology.tags.join(", ");
  const slashTooltip = AppTranslations[pageLanguage].components.skillDashboard.TOOLTIP_TECHNOLOGY_BAR;

  const tooltipClassName = TooltipStyleClass();

  return (
    <ContainerTechnology className="Technologies" onClick={() => navigate(technology.name)}>
      <header>
        <TechName className="Technology-name">{technology.name}</TechName>
      </header>
      <Slash
        tooltipClassName={tooltipClassName.customWidth}
        color={technology.color}
        percentageValue={technology.skillPercentage}
        toolTipMessage={buildSlashTooltipMessage(slashTooltip, technology.name, technology.totalProjects, totalProjects)}
        total={totalProjects}
        value={technology.totalProjects}
      />
      <Tooltip title={tagsString} arrow classes={{ tooltip: tooltipClassName.customWidth }}>
        <footer>{technology.tags.length < 1 ? <br /> : <Tags className="Technology-tags">{tagsString}</Tags>}</footer>
      </Tooltip>
    </ContainerTechnology>
  );
};

const buildSlashTooltipMessage = (message: string, technologyName: string, value: number, total: number) => {
  return message
    .replace("#VALUE#", value.toString())
    .replace("#TECH#", technologyName.toLocaleUpperCase())
    .replace("#TOTAL#", total.toString());
};
