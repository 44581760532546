import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';

import { FacebookIcon, LinkedInIcon, GitHubIcon } from '../Icons';

import { Container } from './styles';

import { Button, TransitionEffect } from '..';

type Media = {
  name: string;
  icon: SvgIconComponent;
  href: string;
  color: string;
};

type Props = {
  id?: string;
  inTransitionEffect?: boolean;
  timeStartTransitionEffect?: number;
  timeExitTransitionEffect?: number;
};

export const SocialMedia: React.FC<Props> = ({ id = '', inTransitionEffect, timeStartTransitionEffect, timeExitTransitionEffect }) => {
  return (
    <TransitionEffect start={inTransitionEffect} timeStart={timeStartTransitionEffect} timeExit={timeExitTransitionEffect}>
      <Container id={id}>
        {medias.map((media) => (
          <Button
            key={media.name}
            title={media.name}
            color={media.color}
            component="a"
            style={{ rel: 'noopener noreferrer', target: '_blank' }}
            href={media.href}
            target="_blank"
            icon={media.icon}
          />
        ))}
      </Container>
    </TransitionEffect>
  );
};

export default SocialMedia;

/* eslint-disable max-len */
const MediumIcon = () => (
  <SvgIcon>
    <path d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.237 4.999-5 5zm.97-5.649v-.269l-1.247-1.224c-.11-.084-.165-.222-.142-.359v-8.998c-.023-.137.032-.275.142-.359l1.277-1.224v-.269h-4.422l-3.152 7.863-3.586-7.863h-4.638v.269l1.494 1.799c.146.133.221.327.201.523v7.072c.044.255-.037.516-.216.702l-1.681 2.038v.269h4.766v-.269l-1.681-2.038c-.181-.186-.266-.445-.232-.702v-6.116l4.183 9.125h.486l3.593-9.125v7.273c0 .194 0 .232-.127.359l-1.292 1.254v.269h6.274z" />
  </SvgIcon>
);

const medias: Media[] = [
  {
    name: 'Github',
    icon: GitHubIcon,
    href: 'https://github.com/matheusicaro',
    color: 'inherit',
  },
  {
    name: 'Linkedin',
    icon: LinkedInIcon,
    href: 'https://www.linkedin.com/in/matheusicaro/',
    color: '#00a5ff',
  },
  {
    name: 'Facebook',
    icon: FacebookIcon,
    href: 'https://www.facebook.com/matheus.icaro.5',
    color: '#455de2',
  },
  {
    name: 'Medium',
    icon: MediumIcon,
    href: 'https://medium.com/@matheusicaro',
    color: 'inherit',
  },
];
