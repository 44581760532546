import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { bindActionCreators, Dispatch } from 'redux';

import { TransitionEffect } from '../../components';
import Profile from '../../components/Profile';
import { PageLanguage, RouteType } from '../../constants/types';
import { ApplicationState } from '../../store';
import { navigate as navigateMenu } from '../../store/ducks/menu';
import BrowserUtils from '../../Utils/BrowserUtils';

import { WorkExperience, Skills, Education, HighlightsAndLanguages } from './components';
import Banner from './components/Banner';
import { Container, PROFILE_CSS_ID } from './styles';

type StateProps = {
  pageLanguage: PageLanguage;
};

type DispatchProps = {
  navigateMenu: (route: RouteType) => void;
};

const Home: React.FC<StateProps & DispatchProps> = ({ pageLanguage, navigateMenu }) => {
  const history = useHistory();
  const navigate = (route: RouteType) => {
    navigateMenu(route);
    history.push(route.path);
  };

  BrowserUtils.scrollUp();

  return (
    <TransitionEffect>
      <main>
        <Container>
          <Banner className="Global-paper" pageLanguage={pageLanguage} />
          <Profile idCss={PROFILE_CSS_ID} className="Global-paper" navigate={navigate} />
          <WorkExperience idCss="Home-workexperience" idCssNodeBefore={PROFILE_CSS_ID} navigate={navigate} pageLanguage={pageLanguage} />
          <Skills idCss="Home-skills" navigate={navigate} pageLanguage={pageLanguage} />
          <HighlightsAndLanguages idCss="Home-highlightsandlanguages" pageLanguage={pageLanguage} navigate={navigate} />
          <Education idCss="Home-education" navigate={navigate} pageLanguage={pageLanguage} />
        </Container>
      </main>
    </TransitionEffect>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  pageLanguage: state.pageLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ navigateMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
