import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import styled from 'styled-components';

import { LoadingSpin } from '../../components';
import { ROUTES } from '../../constants/routesConstants';
import Api from '../../services/api';
import StringUtils from '../../Utils/StringUtils';

type Props = {
  urlPath: string;
};

type State = {
  loading: boolean;
  originRoutePath: string;
};

const initialState = (): State => ({ loading: true, originRoutePath: '' });

const RouteRedirector: React.FC<Props> = (props) => {
  const [state, setState] = useState(initialState());

  const loadRouteRequest = () => {
    Api.getOriginRoute<OriginRouteDTO>(props.urlPath)
      .then((r) => setState({ loading: false, originRoutePath: r.path }))
      .catch(() => setState({ loading: false, originRoutePath: ROUTES.WORK_EXPERIENCE_PROJECTS.path }));
  };

  if (StringUtils.isNotNullOrEmpty(state.originRoutePath)) {
    return <Redirect to={state.originRoutePath} />;
  } else {
    loadRouteRequest();
  }

  return (
    <Section>
      <LoadingSpin />
    </Section>
  );
};

type OriginRouteDTO = {
  path: string;
};

export default RouteRedirector;

const Section = styled.section`
  height: 92vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .Loading-progress-circle {
    position: initial !important;
    margin: 0 !important;
  }
`;
