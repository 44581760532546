import { css } from 'styled-components';

export const cssEducationWidthMin580 = css`
  .College-item {
    flex-direction: column;
  }
  .College-date {
    font-size: 0.7rem;
  }
  .College-titles {
    font-size: 0.9rem;
    padding-left: 2em;
  }
  .Course-circle-topic {
    margin-left: 0% !important;
  }
  .College-topic {
    flex-direction: row-reverse;
  }
`;

export const cssEducationWidthMin380 = css`
  .College-titles {
    padding-left: 0em;
  }
`;
