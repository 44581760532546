import React, { CSSProperties } from 'react';

import { Pagination as PaginationMaterial } from '@material-ui/lab';

type Props = {
  children?: never;
  className?: string;
  id?: string;
  elementsPerPage: number;
  handleClick(page: number): void;
  totalElements: number;
  showPagination?: boolean;
  page: number;
  hiddenWhenOnePage?: boolean;
};

const Pagination: React.FC<Props> = ({
  id = '',
  showPagination = true,
  className = '',
  elementsPerPage,
  totalElements,
  handleClick,
  page,
  hiddenWhenOnePage = true,
}) => {
  const paginationCount = Math.ceil(totalElements / elementsPerPage);
  const style: CSSProperties = hiddenWhenOnePage && elementsPerPage >= totalElements ? { visibility: 'hidden' } : {};

  return (
    <PaginationMaterial
      id={id}
      style={style}
      className={`Global-pagination ${className}`}
      onChange={(a, page) => handleClick(page)}
      count={paginationCount}
      shape="rounded"
      page={page}
    />
  );
};

export default Pagination;
