import React from "react";

type Props = {
  children?: never;
  text: string;
};

const SelectedText: React.FC<Props> = ({ text }) => {
  return <span className="Card-filter-selected-true">{text}</span>;
};

export default SelectedText;
