import React from 'react';

import { CardActionArea, Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import { PageLanguage, Post } from '../../../constants/types';
import DateUtils from '../../../Utils/DateUtils';

type Props = {
  pageLanguage: PageLanguage;
  post: Post;
  postType: string;
  onClick: () => void;
};

const PostCard: React.FC<Props> = ({ post, pageLanguage, postType, onClick }) => {
  return (
    <Section onClick={onClick}>
      <Card>
        <CardActionArea>
          <img src={post.imageLink} alt={post.imageLink} />
          <CardContent className="Card-info">
            <PostCardData date={post.date} pageLanguage={pageLanguage} />
            <PostCardType postType={postType} />
            <Typography className="Card-title" gutterBottom variant="body1" component="h2">
              {post.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Section>
  );
};

export default PostCard;

export const PostCardData: React.FC<{ date: Date; pageLanguage: PageLanguage }> = ({ date, pageLanguage }) => {
  return (
    <Typography className="Card-date" variant="body2" color="textSecondary" component="p">
      {DateUtils.getDateTranslate(pageLanguage, date)}
    </Typography>
  );
};

export const PostCardType: React.FC<{ postType: string }> = ({ postType }) => {
  return (
    <Typography className="Card-type" variant="body2" color="textSecondary" component="p">
      {postType}
    </Typography>
  );
};

const Section = styled.section`
  width: 100%;
  .Card-info {
    width: 100%;
  }

  .MuiCardActionArea-root {
    display: flex;
  }

  .MuiCardContent-root {
    padding-top: 0;
  }
  .Card-date {
    margin-bottom: 15px;
  }
  .Card-title {
    margin: 0px;
    margin-top: 5px;
  }
  .Card-type {
    color: ${({ theme }) => theme.colors.text.subTitle};
  }

  img {
    max-width: 20%;
    min-width: 20%;
  }

  @media (max-width: 1024px) {
    img {
      min-width: 25%;
      max-width: 25%;
    }
  }

  @media (max-width: 600px) {
    img {
      min-width: 40%;
      max-width: 40%;
    }
  }
`;
