import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOffIcon } from "@material-ui/icons";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";

import { AppTranslations } from "../../assets/translations";
import { Workplace, PageLanguage } from "../../constants/types";
import { ApplicationState } from "../../store";
import { changeWorkplace } from "../../store/ducks/workplace";
import { cssBackgroundGradientEffect } from "../../styles/cssGlobalCustom";
import LinkButton from "../Buttons/LinkButton";
import Divider from "../Divider";
import { GitHubIcon, BusinessIcon } from "../Icons";
import { SubTopic } from "../Typography";

type StateProps = {
  workplace: Workplace;
  pageLanguage: PageLanguage;
};

type DispatchProps = {
  changeWorkplace(workplace: Workplace): void;
};

type OwnProps = {
  idCss?: string;
  children?: never;
};

type Props = StateProps & OwnProps & DispatchProps;

const ToggleWorkspace: React.FC<Props> = ({ idCss = "", pageLanguage, workplace, changeWorkplace }) => {
  const [active, setActive] = useState({
    jobs: workplace === "both" || workplace === "jobs",
    github: workplace === "both" || workplace === "github",
  });

  const { TEXT_COMPANIES, TEXT_SELECTED } = AppTranslations[pageLanguage].components.workplace;

  useEffect(() => {
    if (active.jobs && active.github && workplace !== "both") changeWorkplace("both");
    else if (active.jobs && !active.github && workplace !== "jobs") changeWorkplace("jobs");
    else if (active.github && !active.jobs && workplace !== "github") changeWorkplace("github");
  }, [active.jobs, active.github]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workplace === "both" && (!active.github || !active.jobs)) setActive({ github: true, jobs: true });
    else if (workplace === "jobs" && !active.jobs) setActive({ github: false, jobs: true });
    else if (workplace === "github" && !active.github) setActive({ github: true, jobs: false });
  }, [workplace]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id={idCss}>
      <Button
        active={active.jobs}
        disable={active.jobs && !active.github}
        icon={<BusinessIcon />}
        onClick={() => setActive((prev) => ({ ...prev, jobs: !prev.jobs }))}
        text={TEXT_COMPANIES}
        textSelected={TEXT_SELECTED}
      />

      <Divider position="vertical" />

      <Button
        active={active.github}
        disable={active.github && !active.jobs}
        icon={<GitHubIcon />}
        iconBefore
        leftButton
        onClick={() => setActive((prev) => ({ ...prev, github: !prev.github }))}
        text="GitHub"
        textSelected={TEXT_SELECTED}
      />
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  workplace: state.workplace,
  pageLanguage: state.pageLanguage,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ changeWorkplace }, dispatch);

const Button = (props: {
  active: boolean;
  disable: boolean;

  icon: JSX.Element;
  iconBefore?: boolean;
  leftButton?: boolean;
  onClick: () => void;
  text: string;
  textSelected: string;
}) => {
  return (
    <ButtonStyle active={props.active} disable={props.disable}>
      <LinkButton
        disabled={props.disable}
        id={`Toogle-button-${props.leftButton ? "left" : "right"}`}
        noDefaultStyle
        text={props.text}
        iconBefore={props.iconBefore}
        icon={props.icon}
        size="large"
        handleClick={props.onClick}
      />

      <ActivationLabel>
        <SubTopic fontStyle="normal">{props.textSelected}</SubTopic>
        {props.active ? <CheckBoxIcon /> : <CheckBoxOffIcon />}
      </ActivationLabel>
    </ButtonStyle>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleWorkspace);

const Container = styled.div`
  display: flex;
  flex-flow: nowrap;
  justify-content: center;

  .Divider-component {
    margin: 0 5px !important;
  }

  button {
    border: 0 !important;
  }

  #Toogle-button-right {
    svg {
      margin-left: 10px;
    }
  }

  #Toogle-button-left {
    svg {
      margin-right: 10px;
    }
  }

  @media (max-width: 320px) {
    button {
      font-size: 0.6em;
      svg {
        width: 0.8em;
      }
    }
  }
`;

const ButtonStyle = styled.span<{ active: boolean; disable: boolean }>`
  text-align: center;

  button {
    border: 2px ${({ theme }) => theme.colors.background.primary} solid !important;

    ${({ disable }) => (disable ? "background-color: inherit !important" : cssBackgroundGradientEffect)};

    ${({ active, theme }) =>
      !active
        ? ""
        : `
          &,
          svg {
            color: ${theme.colors.primary.light} !important;
          }
          opacity: 1 !important;
    `}

    :hover {
      border-color: ${({ theme }) => theme.colors.background.border} !important;
    }
  }
`;

const ActivationLabel = styled.div`
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;

  & > h6 {
    line-height: 0;
    padding-top: 0;
    margin-bottom: 0;
  }

  svg {
    font-size: 1rem;
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.disable};
  }
`;
