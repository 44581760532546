import React, { Fragment } from 'react';

import { PagesTranslations } from '../../../assets/translations';
import { Jobs } from '../../../components';
import PrintPageContent, { TitlePageContent as Title } from '../../../components/PrintPageContent';
import { YEARS_AS_SOFTWARE_DEVELOPER } from '../../../constants/constants';
import { PageLanguage } from '../../../constants/types';

type Props = {
  showJob?: string;
  pageLanguage: PageLanguage;
};

const JobsPage: React.FC<Props> = ({ showJob, pageLanguage }) => {
  const { TEXT_INTRO, TEXT_TITLE } = PagesTranslations[pageLanguage].JOBS;
  return (
    <Fragment>
      <Title>{TEXT_TITLE}</Title>
      <br />
      <PrintPageContent replace={{ index: 1, text: '#YEAR#', newText: YEARS_AS_SOFTWARE_DEVELOPER }} pageContent={TEXT_INTRO.content} />
      <br />
      <Jobs commonPage showJobId={showJob} />
    </Fragment>
  );
};

export default JobsPage;
