import React from 'react';

import styled from 'styled-components';

import { AppTranslations } from '../../../assets/translations';
import { PageLanguage, WorkExperience } from '../../../constants/types';
import { Subtitle as Title, SubtitleSmall } from '../../Typography';

type Props = {
  children?: never;
  isMobilePhone: boolean;
  job: WorkExperience;
  homepage: boolean;
  pageLanguage: PageLanguage;
};

const JobHeader: React.FC<Props> = ({ isMobilePhone, job, homepage, pageLanguage }) => {
  const { TEXT_PREFIX_COMPANY } = AppTranslations[pageLanguage].components.jobs;

  const JobCompany = () => <span className="Job-company">{` - ${TEXT_PREFIX_COMPANY} ${job.company.name}`}</span>;

  return (
    <Header className="Job-header hover-effect" homepage={homepage}>
      <span>
        <Title className="Job-title">
          {job.position}
          {!isMobilePhone && <JobCompany />}
        </Title>
        {isMobilePhone && !homepage && <JobTime text={job.totalTime} />}
      </span>
      {!isMobilePhone && <JobTime text={job.totalTime} />}
    </Header>
  );
};

export default JobHeader;

export const JobTime = ({ text, className = '' }: { text: string; className?: string }) => (
  <SubtitleSmall className={`Job-time ${className}`}>{text}</SubtitleSmall>
);

export const Header = styled.header<{ homepage: boolean }>`
  max-height: 40px;

  display: flex;
  text-align: start;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;

  &,
  .Job-title,
  .Job-time {
    margin: 0;
    padding: 0;
  }
  span {
    max-height: inherit;
    margin-right: 25px;

    .Job-title {
      width: 100%;
    }
  }
  .Job-company {
    font-weight: normal !important;
  }
  .Job-title {
    font-weight: bold !important;
    padding-right: 10px;
  }

  .Job-time {
    white-space: nowrap;
    display: contents;
  }

  .Job-time {
    ${({ homepage }) => (homepage ? '' : 'font-weight: normal !important;')}
  }
`;
