import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Clear } from "@material-ui/icons";
import styled from "styled-components";

import { debounce } from "../../Utils";

type Props = {
  children?: never;
  idCss?: string;
  className?: string;
  initialSearch?: string;
  fetchFilter(filter: string, finishLoadingSearch: () => void, clearSearch: () => void): void;
  text: string;
  disabled?: boolean;
  forceSearch?: boolean;
};

const SearchSelect: React.FC<Props> = ({ disabled, idCss = "", className = "", fetchFilter, text, initialSearch = "", forceSearch }) => {
  const [value, setValue] = useState(initialSearch);
  const [loading, setLoading] = useState(false);

  const finishLoadingSearch = () => setLoading(false);
  const fetch = (currentValue: string) => fetchFilter(currentValue.toLowerCase(), finishLoadingSearch, () => setValue(""));

  const fetchWithLoading = (currentValue: string) => {
    currentValue && setLoading(true);
    setTimeout(() => fetch(currentValue), 1000);
  };

  const search = (value: string) => debounce(() => fetchWithLoading(value), 1000);

  const goToSearchOrFetch = (data: string) => {
    if (value) return search(value);
    else fetch(value);
  };

  useEffect(() => {
    goToSearchOrFetch(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (forceSearch) goToSearchOrFetch(value);
  }, [forceSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      id={idCss}
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
        search(value);
      }}
    >
      <Input
        disabled={disabled}
        label={text}
        placeholder="Java, JavaScript, React, BACK-END, FULL-STACK..."
        value={value}
        variant="outlined"
        onChange={(event) => setValue(event.target.value)}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress size={20} /> : value ? <Clear onClick={() => setValue("")} /> : ""}
            </React.Fragment>
          ),
        }}
      />
    </form>
  );
};

export default SearchSelect;

const Input = styled(TextField)`
  input {
    font-family: monospace;
  }
`;
