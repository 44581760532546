import React, { Fragment } from 'react';

import styled from 'styled-components';

import { PagesTranslations } from '../../../assets/translations';
import { PrintPageContent } from '../../../components';
import { SubtitleSmall as Text } from '../../../components/Typography';
import { College, PageLanguage } from '../../../constants/types';
import DateUtils from '../../../Utils/DateUtils';

type Props = {
  pageLanguage: PageLanguage;
  colleges: Array<College>;
};

const SpecializationView: React.FC<Props> = (props: Props) => {
  const { SPECIALIZATION, TEXT_CERTIFICATE } = PagesTranslations[props.pageLanguage].EDUCATION;

  const CollegeView = ({ college }: { college: College }) => {
    if (!college.specialization) return <Fragment />;
    const date = DateUtils.getFullDateWithTranslationMonths(props.pageLanguage, college.courseStart, college.courseEnd);
    return (
      <li key={college.courseName}>
        {college.courseName}
        <ul>
          <li>
            <Text>{`${college.schoolName} - ${college.schoolLocation}`}</Text>
          </li>
          <li>
            <Text>{`${date.startMonth} ${date.startDate.getFullYear()} - ${date.finalMonth} ${date.finalDate.getFullYear()}`}</Text>
          </li>
          {college.certificateLink && (
            <li>
              <a href={college.certificateLink} target="_blank" rel="noopener noreferrer">
                {TEXT_CERTIFICATE}
              </a>
            </li>
          )}
        </ul>
      </li>
    );
  };

  return (
    <Fragment>
      <PrintPageContent pageContent={SPECIALIZATION.content} />
      <Ul>
        {props.colleges.map((college, i) => (
          <CollegeView key={i} college={college} />
        ))}
      </Ul>
    </Fragment>
  );
};

export default SpecializationView;

const Ul = styled.ul`
  width: 100%;

  &,
  ul {
    padding: 0 5%;
  }

  h5 {
    margin-bottom: 0;
  }
`;
