import { css } from 'styled-components';

export const cssProfileWidthMin1250 = css`
  #Profile-avatar {
    margin-left: -20px;

    @media (min-width: 940px) {
      border-radius: 30px;
      img {
        border-radius: 15px;
      }
    }
  }
`;

export const cssProfileWidthMin1024 = css`
  #Profile-info {
    padding-left: 0%;

    #Profile-particulars-position {
      font-size: 1.2rem;
    }
    #Profile-particulars-info {
      .Topics-info,
      .Profile-particulars-info-topics > svg {
        font-size: 0.81rem;
        line-height: 1.8rem;
      }
    }
  }
`;

export const cssProfileWidthMin940 = css`
  #Profile-avatar {
    margin-left: 0px;
  }
  #Home-profile {
    flex-direction: column;
    padding: 4% 5% !important;
    #Profile-info {
      padding: 0%;
      margin-top: 4%;

      #Profile-title {
        text-align: center;
      }
    }
  }
`;

export const cssProfileWidthMin600 = css`
  #Profile-avatar {
    width: 150px;
    height: 150px;
  }
  #Home-profile #Profile-info {
    #Profile-title-Divider {
      width: 60%;
      justify-self: center;
    }

    #Profile-particulars {
      display: grid;
      grid-template-columns: auto;
      justify-items: center;
      place-items: stretch;
      padding-left: 0%;
      grid-gap: 1em;

      #Profile-particulars-position {
        text-align: center;
        margin-bottom: 2%;
      }

      #Profile-particulars-info > ul {
        display: grid;
        grid-template-columns: auto;
        grid-column-gap: 5em;
        justify-content: center;

        .Topics-info,
        .Profile-particulars-info-topics > svg {
          font-size: 0.91rem;
        }
        .Profile-particulars-info-topics {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const cssProfileWidthMin500 = css`
  #Home-profile #Profile-info {
    #Profile-title {
      margin-top: 1.5%;
    }
    #Profile-title-Divider {
      width: 75%;
    }
  }
`;

export const cssProfileWidthMin420 = css`
  #Home-profile #Profile-info {
    #Profile-title-Divider {
      width: 100%;
    }
  }
`;
