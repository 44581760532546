import React from "react";

import { AppTranslations, PagesTranslations } from "../../../assets/translations";
import { Divider, Error, LoadingSpin, ToggleWorkspace } from "../../../components";
import DialogHowItsWork from "../../../components/Dialog/HowItsWorkDialog";
import { TitlePageContent as Title } from "../../../components/PrintPageContent";
import { PageLanguage, ProjectDTO, DisplayType, Workplace, RouteType } from "../../../constants/types";

import Introduction from "./components/Introduction";
import NotFoundProjectMessage from "./components/NotFoundProjectMessage";
import ProjectsContainer from "./components/ProjectsContainer";
import { buildCategoryOptions, buildOptions } from "./helpers";
import ProjectsSearch from "./ProjectsSearch";
import { Container } from "./styles";
import { CompanyFiltersType } from "./types";

type Props = {
  changeWorkplace(workplace: Workplace): void;
  children?: never;
  companyFilters: Array<CompanyFiltersType>;
  displayType: DisplayType;
  forceFilterTech?: boolean;
  handleClickPagination(page: number): void;
  handleFilterProjectsByCompany(filter?: string): void;
  handleFilterProjectsByTech(filter: string, finishLoadingSearch: () => void): void;
  handleFilterProjectsByYear(filter?: string): void;
  handleSelectOrderBy: (value?: string) => void;
  idCssHeader: string;
  isError: boolean;
  isFilterActive: boolean;
  isLoading: boolean;
  navigate(route: RouteType): void;
  onClickTryAgain(): void;
  page: number;
  pageLanguage: PageLanguage;
  projects: Array<ProjectDTO>;
  projectsPerPage: number;
  selectedCompanyFilter: string;
  selectOderByOption?: string;
  selectedTechFilters: Array<string>;
  selectedTechFilterUserInput?: string;
  selectedYearFilter: string;
  totalProjects: number;
  totalProjectsPagination: number;
  workplace: Workplace;
  yearFilters: Array<string>;
};

const ProjectsPageView: React.FC<Props> = (props) => {
  const { isError, isLoading, page, projects, totalProjects, selectedTechFilters, totalProjectsPagination } = props;
  const { TEXT_NOT_FOUND_FILTER, TEXT_DEFAULT_NOT_FOUND } = AppTranslations[props.pageLanguage].info;
  const { DIALOG_HOW_ITS_WORKS, TEXT_TITLE } = PagesTranslations[props.pageLanguage].PROJECTS;

  const projectsIsEmpty = projects.length < 1;

  return (
    <Container>
      <Title>{TEXT_TITLE}</Title>

      <br />
      <Introduction pageLanguage={props.pageLanguage} />
      <br />

      <Divider position="horizontal" />

      <br />
      <ToggleWorkspace />
      <br />

      <DialogHowItsWork title={DIALOG_HOW_ITS_WORKS.TEXT_TITLE} content={DIALOG_HOW_ITS_WORKS.content} pageLanguage={props.pageLanguage} />

      <ProjectsSearch
        disabled={isLoading || isError}
        displayType={props.displayType}
        forceFilterTech={props.forceFilterTech}
        handleClickPagination={props.handleClickPagination}
        handleFilterProjectsByCompany={props.handleFilterProjectsByCompany}
        handleFilterProjectsByTech={props.handleFilterProjectsByTech}
        handleFilterProjectsByYear={props.handleFilterProjectsByYear}
        handleSelectOrderBy={props.handleSelectOrderBy}
        idCssHeader={props.idCssHeader}
        isFilterActive={props.isFilterActive}
        optionsToCategories={buildCategoryOptions(props.companyFilters, props.pageLanguage)}
        optionsToYears={buildOptions(props.yearFilters)}
        page={props.page}
        pageLanguage={props.pageLanguage}
        projectsIsEmpty={projectsIsEmpty}
        projectsPerPage={props.projectsPerPage}
        selectedCompanyFilter={props.selectedCompanyFilter}
        selectOderByOption={props.selectOderByOption}
        selectedTechFilterInputText={props.selectedTechFilterUserInput}
        selectedYearFilter={props.selectedYearFilter}
        totalProjects={totalProjects}
        totalProjectsPagination={totalProjectsPagination}
        workplace={props.workplace}
      />

      {isError && <Error onClickTryAgain={() => props.onClickTryAgain()} />}
      {isLoading && <LoadingSpin style={{ marginTop: "10%", marginLeft: "48%" }} />}

      {!isError && !isLoading && projectsIsEmpty && (
        <NotFoundProjectMessage
          filterOfCompany={props.selectedCompanyFilter}
          filterOfTechs={selectedTechFilters}
          filterOfYear={props.selectedYearFilter}
          message={TEXT_NOT_FOUND_FILTER}
          title={TEXT_DEFAULT_NOT_FOUND}
          totalProjects={props.totalProjects}
        />
      )}

      {!isError && !isLoading && !projectsIsEmpty && (
        <ProjectsContainer
          navigate={props.navigate}
          pageLanguage={props.pageLanguage}
          paginationElementsPerPage={props.projectsPerPage}
          paginationHandleClick={props.handleClickPagination}
          paginationPage={page}
          paginationProjectsPerPage={props.projectsPerPage}
          paginationTotalElements={totalProjectsPagination}
          projects={projects}
          selectedCompanyFilter={props.selectedCompanyFilter}
          selectedTechFilters={selectedTechFilters}
          selectedYearFilter={props.selectedYearFilter}
          workplace={props.workplace}
        />
      )}
    </Container>
  );
};

export default ProjectsPageView;
