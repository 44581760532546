import styled from 'styled-components';

export const TopicsLi = styled.li`
  display: flex;
  align-items: center;

  .Topics-info,
  .MuiSvgIcon-root {
    font-style: normal;
    font-size: 0.71rem;
    margin: 0;
  }

  .Topic-email a {
    color: inherit;
    :hover {
      color: ${({ theme }) => theme.colors.text.hyperlink};
    }
  }

  .MuiSvgIcon-root {
    margin-right: 2%;
    margin-top: 1px;
    color: ${({ theme }) => theme.colors.disable};
  }
`;

export const Particulars = styled.section`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 2em;
  grid-template-columns: repeat(3, auto);
`;

export const Characters = styled.section`
  white-space: nowrap;
  #Profile-particulars-position {
    margin-bottom: 5%;
    @media (min-width: 1024px) and (max-width: 1400px) {
      white-space: pre-wrap;
    }
  }
`;
