import React from 'react';

import Button from '@material-ui/core/Button';

import { setTimeoutOnClick } from '../../Utils';
import { ArrowBefore, ArrowNext } from '../Icons';

type Props = {
  className?: string;
  id?: string;
  handleClick?: () => void;
  children?: never;
  noIcon?: any;
  noText?: any;
  position?: 'center' | 'end';
  size?: 'small' | 'medium' | 'large';
  text: string;
  styleText?: boolean;
  icon?: JSX.Element;
  iconBefore?: boolean;
  noDefaultStyle?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const LinkButton: React.FC<Props> = ({
  className = '',
  id = '',
  text,
  handleClick,
  noIcon = false,
  noText = false,
  position = 'center',
  size = 'small',
  styleText,
  icon,
  iconBefore,
  noDefaultStyle,
  disabled,
  style,
}) => {
  let classeGlobalStyleButton = styleText ? `Global-Button-text-${position}` : `Global-Button-${position}`;
  let styleIcon = noIcon || noText ? '' : 'Global-button-icon';
  const onClickWithTimeout = handleClick ? () => setTimeoutOnClick(handleClick) : handleClick;

  if (noDefaultStyle) {
    classeGlobalStyleButton = '';
    styleIcon = '';
  }

  if (iconBefore) {
    return (
      <Button
        id={id}
        style={style}
        className={`${classeGlobalStyleButton} ${styleIcon} ${className}`}
        size={size}
        variant="outlined"
        onClick={onClickWithTimeout}
        disabled={disabled}
      >
        {icon ? icon : <ArrowBefore />}
        {!noText && text}
      </Button>
    );
  }

  return (
    <Button
      id={id}
      style={style}
      className={`${classeGlobalStyleButton} ${styleIcon} ${className}`}
      size={size}
      variant="outlined"
      onClick={onClickWithTimeout}
      disabled={disabled}
    >
      {!noText && text}
      {!noIcon && (icon ? icon : <ArrowNext />)}
    </Button>
  );
};

export default LinkButton;
