import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import styled, { css } from "styled-components";

import { TransitionEffect, Profile, NavigationBar } from "../../..";
import { RouteType } from "../../../../constants/types";
import { ApplicationState } from "../../../../store";

import { GradientEffect, disableGradientEffect, GradientHeight } from "./styles/stylesGradientEffect";

type State = {
  gradientEffect: {
    isActive: boolean;
    containerHeight: number;
  };
};

type StateProps = {
  displayHeight: number;
};

type OnwProps = {
  closeDrawerMenu: () => void;
  isMenuOpen: boolean;
  isMobileDevice: boolean;
  isMobilePhoneSM: boolean;
  navigate: (route: RouteType) => void;
};

const ID_CONTENT = "Drawer-content";

const ContentDrawer: React.FC<StateProps & OnwProps> = ({
  closeDrawerMenu,
  displayHeight,
  isMenuOpen,
  isMobileDevice,
  isMobilePhoneSM,
  navigate,
}) => {
  const [state, setState] = useState(initialState());
  const closeMenu = isMenuOpen ? closeDrawerMenu : undefined;

  const profileRendered = () => handleScrollActivedDrawerMenu(state, setState);

  useEffect(() => {
    let mounted = true;
    if (mounted) handleScrollActivedDrawerMenu(state, setState);
    return () => {
      mounted = false;
    };
  }, [displayHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TransitionEffect timeout={2000}>
      <Container id={ID_CONTENT} isActiveGradient={state.gradientEffect.isActive} ContentHeight={state.gradientEffect.containerHeight}>
        <Profile idCss="Drawer-content-profile" position={"side"} disableLastDivisor={isMobileDevice} onRendered={profileRendered} />
        <NavigationBar idCss="Drawer-content-menu" onClickMenu={closeMenu} navigate={navigate} />
      </Container>
    </TransitionEffect>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  displayHeight: state.displaySize.height,
});

export default connect(mapStateToProps)(ContentDrawer);

const newStateGradientEffect = (active: boolean, height?: number) => ({
  isActive: active,
  containerHeight: height || 0,
});

const initialState = (): State => ({
  gradientEffect: newStateGradientEffect(false, 0),
});

type SetState = React.Dispatch<React.SetStateAction<State>>;

const handleScrollActivedDrawerMenu = (currentState: State, setState: SetState) => {
  const container = document.getElementById(ID_CONTENT);
  if (container) {
    const isScrollActive = container.scrollHeight > container.clientHeight;
    const { isActive, containerHeight } = currentState.gradientEffect;

    if (isScrollActive) {
      const isChangedSize = container.clientHeight !== containerHeight;

      if (!isActive || isChangedSize) {
        setState((prev) => ({ gradientEffect: newStateGradientEffect(true, container.clientHeight) }));
      }
    } else {
      isActive && setState((prev) => ({ gradientEffect: newStateGradientEffect(false) }));
    }
  }
};

const spaceWhenIsGradientActive = css`
  @media (min-width: 501px) and (max-width: 1024px) {
    #Menu-nav-buttons-first > button {
      padding-top: ${GradientHeight}px !important;
    }

    #Menu-nav-buttons-before-last > button,
    #Menu-nav-buttons-last > button {
      padding-bottom: ${GradientHeight}px !important;
    }
  }

  @media (max-width: 500px) {
    #Drawer-content-profile {
      margin-top: ${GradientHeight}px !important;
    }
    #Menu-nav-buttons-before-last .Menu-nav-buttons-li,
    #Menu-nav-buttons-last .Menu-nav-buttons-li {
      padding-bottom: ${GradientHeight}px !important;
    }
  }

  @media (min-height: 860px), (max-width: 260px) {
    #Menu-nav-buttons-before-last > button,
    #Menu-nav-buttons-before-last .Menu-nav-buttons-li {
      padding-bottom: 1vh !important;
    }
  }
`;

export const Container = styled.section<{ isActiveGradient: boolean; ContentHeight: number }>`
  ${({ isActiveGradient, ContentHeight }) => (isActiveGradient ? GradientEffect(ContentHeight) : disableGradientEffect)}
  ${({ isActiveGradient }) => (isActiveGradient ? spaceWhenIsGradientActive : "")}
`;
