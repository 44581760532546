import styled, { css } from 'styled-components';

const mediaQueries = css`
  @media (max-width: 1600px) {
    #Commonpage-article {
      width: 70%;
      margin-left: 19.75%;
    }
  }

  @media (max-width: 1250px) {
    #Commonpage-article {
      width: 65%;
      margin-left: 24.75%;
    }
  }

  @media (min-width: 681px) {
    #Commonpage-article {
      border-color: ${({ theme }) => theme.colors.background.primary} !important;
    }
  }

  @media (min-width: 1024px) and (max-height: 840px) {
    #Commonpage-article {
      width: 60%;
    }
    @media (max-width: 2300px) {
      #Commonpage-article {
        margin-left: 29.5%;
      }
    }
    @media (max-width: 1800px) {
      #Commonpage-article {
        margin-left: 30%;
      }
    }

    @media (max-width: 1600px) {
      #Commonpage-article {
        width: 65%;
      }
    }
  }

  @media (max-width: 1024px) {
    #Commonpage-article {
      width: 80%;
      padding-left: 2%;
      padding-right: 2%;
      margin-left: 10%;
    }

    @media (max-height: 700px) {
      #Commonpage-article {
        margin-left: 13%;
      }

      @media (max-width: 768px) {
        #Commonpage-article {
          margin-left: 14%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    #Commonpage-article {
      width: 81%;
    }
  }

  @media (max-width: 500px) {
    #Commonpage-article {
      width: 100%;
      margin: 0;
      padding: 2% 4%;
      padding-top: 110px;
      border: none !important;
    }
    #Commonpage-article-button-back {
      left: 10px;
      bottom: 0;
      margin-bottom: 10px;
    }
  }

  @media (max-height: 940px) {
    #Commonpage-article {
      padding-top: 100px;
      padding-bottom: 20px;
    }
  }
`;

export const Main = styled.main<{ chatOpen: boolean }>`
  min-height: 92vh; 

  #Commonpage-article {
    background: ${({ theme }) => theme.colors.background.primary};
    border-bottom: none !important;
    border-top: none !important;
    padding: 12vh 3vw;
    padding-bottom: 2vh;

    width: 50.4%;
    max-width: 1000px;

    min-height: inherit;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    ul {
      padding: 0 5%;
      font-size: 0.9rem;
    }
  }

  #Commonpage-article-button-back {
    position: fixed;
    background-color: ${({ theme }) => theme.colors.background.secondary};
    z-index: 5500;
  }

  #Commonpage-article-button-back {
    .MuiSvgIcon-root{
      fill: ${({ theme }) => theme.colors.primary.light}
    }
  }

  ${({ chatOpen }) =>
    !chatOpen
      ? ''
      : `
      #Commonpage-aside {
        padding-top: 5%;
        width: 20%;
    
        #Commonpage-divider {
          margin: 15% 0%;
        }
      }
    
      #Commonpage-article {
        margin-left: 20%;
      }
  `}

  /* Styles should  going to after */
  ${mediaQueries}
`;
