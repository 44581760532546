import { cloneDeep, isEmpty } from "lodash";

const isObjectEmpty = (object: any) => !object && isEmpty(object);

const equals = (a: object, b: object) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const notEquals = (a: object, b: object) => !equals(a, b);

function clone<T>(object: T): T {
  return cloneDeep(object);
}

const ObjectUtils = {
  isObjectEmpty,
  equals,
  notEquals,
  clone,
};

export default ObjectUtils;
