import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Zoom as Transition } from '@material-ui/core';
import { Dispatch, bindActionCreators } from 'redux';
import styled from 'styled-components';

import { PrintPageContent, LinkButton, Button } from '../..';
import { AppTranslations } from '../../../assets/translations';
import { WorkExperience, PageLanguage, RouteType } from '../../../constants/types';
import { navigate } from '../../../store/ducks/menu';
import RouterUtils from '../../../Utils/RouterUtils';
import { NewTabIcon } from '../../Icons';
import { SubTopic as TotalTime, SubtitleMedium as Title } from '../../Typography';

import { JobTime } from './JobHeader';

type DispatchProps = {
  navigate: (route: RouteType) => void;
};

type OwnProps = {
  children?: never;
  expandContent: boolean;
  handleExpandContent: VoidFunction;
  isActived: boolean;
  isLastJob: boolean;
  isMobilePhone: boolean;
  job: WorkExperience;
  jobIndex: number;
  pageLanguage: PageLanguage;
  homepage: boolean;
  toggleJob: (jobId: string, jobIndex: number) => void;
};

type Props = OwnProps & DispatchProps;

const JobContent: React.FC<Props> = ({
  expandContent,
  isMobilePhone,
  handleExpandContent,
  isActived,
  isLastJob,
  job,
  jobIndex,
  navigate,
  toggleJob,
  pageLanguage,
  homepage = false,
}) => {
  const { TEXT_BUTTON_PREVIOUS_JOB, TEXT_BUTTON_NEXT_JOB, PROJECTS } = AppTranslations[pageLanguage].components.jobs;
  const { TEXT_SHOW_MORE, TEXT_SHOW_LESS, TEXT_NEW_TAB } = AppTranslations[pageLanguage].links;
  const { TEXT_COMPANY } = AppTranslations[pageLanguage].info;
  const TEXT_SHOW_MORE_OR_LESS = expandContent ? TEXT_SHOW_LESS : TEXT_SHOW_MORE;

  const classCss = homepage ? 'hover-effect' : '';

  const NextJobButton = () => (
    <LinkButton
      id="Job-step-content-nextjobbutton"
      handleClick={() => toggleJob('', jobIndex + 1)}
      text={TEXT_BUTTON_NEXT_JOB}
      position="end"
      styleText
    />
  );

  const PreviousJobButton = () => (
    <LinkButton
      id="Job-step-content-previousjobbutton"
      handleClick={() => toggleJob('', jobIndex - 1)}
      text={TEXT_BUTTON_PREVIOUS_JOB}
      position="end"
      styleText
      iconBefore
    />
  );

  const ShowMoreButton = () => (
    <LinkButton
      id="Job-step-content-showmorebutton"
      handleClick={handleExpandContent}
      position="center"
      styleText
      text={TEXT_SHOW_MORE_OR_LESS}
      noIcon
    />
  );

  const Projects = () => (
    <Fragment>
      <p>{PROJECTS}</p>
      <ul className="Job-content-projects">
        {job.projects.map((p) => {
          const route = RouterUtils.getRouteProjectDetailsWithId(p.id);
          return (
            <li key={p.id}>
              <a className="Projects-button-open-new-tab" href={route.path} title={TEXT_NEW_TAB} target="_blank" rel="noopener noreferrer">
                <Button title={TEXT_NEW_TAB} component="button" icon={NewTabIcon} />
              </a>
              <span className="Projects-list" onClick={() => navigate(route)}>
                {p.name}
              </span>
            </li>
          );
        })}
      </ul>
    </Fragment>
  );

  return (
    <Transition key={job.id} in={isActived || homepage} timeout={homepage ? 0 : 400}>
      <Container className={`Job-content ${classCss}`} active={isActived} isHomePage={homepage}>
        {!homepage && <TotalTime className="Job-time">{job.time}</TotalTime>}

        {!homepage && (
          <Title className="Job-company" style={{ marginTop: '10px' }}>
            {TEXT_COMPANY + ' '}
            <a href={job.company.profileLink} rel="noopener noreferrer" target="_blank" title={job.company.profileLink}>
              {job.company.name}
            </a>
          </Title>
        )}

        {expandContent && !isLastJob && <NextJobButton />}

        {isMobilePhone && homepage && <JobTime className="Job-time-mb" text={job.totalTime} />}

        <p>{job.introduction}</p>

        {expandContent && <PrintPageContent pageContent={job.content} />}
        {expandContent && job.projects.length > 0 && <Projects />}

        {!homepage && <ShowMoreButton />}
        {expandContent && jobIndex > 0 && <PreviousJobButton />}
      </Container>
    </Transition>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ navigate }, dispatch);

export default connect(null, mapDispatchToProps)(JobContent);

const Container = styled.article<{ active: boolean; isHomePage: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: start;
  color: ${(props) => (props.active ? props.theme.colors.text.paragraph : props.theme.colors.text.paragraphSecondary)};

  #Job-step-content-nextjobbutton {
    margin-bottom: 1%;
  }

  #Job-step-content-showmorebutton {
    margin-top: 1%;
    border: solid 1px ${({ theme }) => theme.colors.text.subTitle} !important;
  }

  #Job-step-content-previousjobbutton {
    padding-right: revert !important;
    display: inline-flex !important;
    padding-left: 0;
  }

  .Job-company {
    font-weight: normal;
    margin: 10px 0;

    a {
      color: ${({ theme }) => theme.colors.text.subTitle};
    }
  }

  ul {
    margin: 0 5%;
  }

  .Job-content-projects {
    padding: 2% 5%;
    list-style-type: none;

    .MuiSvgIcon-root {
      width: 0.85em;
    }

    li {
      display: flex;
      align-items: center;
      margin: 20px 0;

      .Projects-list {
        margin-left: 5px;
        color: ${({ theme }) => theme.colors.text.hyperlink} !important;

        :hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .Job-time {
    text-align: end;
    margin-top: 5px;
  }

  @media (max-width: 600px) {
    .Job-time {
      text-align: start;
      ${({ isHomePage }) =>
        isHomePage
          ? ''
          : `
            margin-top: 30px;
            margin-bottom: 0;
      `}
    }
  }

  @media (max-width: 320px) {
    .Job-time-mb {
      text-align: end !important;
    }
    .Job-time {
      ${({ isHomePage }) => (isHomePage ? '' : 'margin-top: 50px;')}
    }
  }
`;
