import React, { useLayoutEffect, useState } from "react";

import styled from "styled-components";

import { Pagination } from "../..";
import { TransitionEffect } from "../..";
import { AppTranslations } from "../../../assets/translations";
import { PageLanguage, Skill, Technology as TechnologyType } from "../../../constants/types";
import BrowserUtils from "../../../Utils/BrowserUtils";
import ListUtils from "../../../Utils/ListUtils";
import { SubTopic } from "../../Typography";

import { Technology } from "./Technology";

type Props = {
  children?: never;
  skill: Skill;
  navigate: (path: string) => void;
  pageLanguage: PageLanguage;
  idCss: string;
};

const PanelSkill: React.FC<Props> = (props: Props) => {
  const [page, setPage] = useState(1);
  const [heightDashboard, setHeightDashboard] = useState(0);

  const { TEXT_INFO_SKILL_DASHBORD, TEXT_INFO_SKILL_DASHBORD_OTHERS } = AppTranslations[props.pageLanguage].info;

  const MAX_TECHNOLOGIES_PER_BOARD = 3;
  const { projects } = props.skill;

  const totalTechnologies = props.skill.technologies.length;
  const showPagination = totalTechnologies > MAX_TECHNOLOGIES_PER_BOARD;
  const minHeightForDashboard = showPagination && heightDashboard > 0 ? `${heightDashboard - DIFFERENCE_HEADER_IN_PIXELS}px` : "auto";
  const technologies = showPagination
    ? ListUtils.getElementsPerPage<TechnologyType>(page, props.skill.technologies, MAX_TECHNOLOGIES_PER_BOARD)
    : props.skill.technologies;

  const handleClickPagination = (page: number) => setPage(page);
  const getSkillName = (skill: Skill) => (skill.name === "others" ? TEXT_INFO_SKILL_DASHBORD_OTHERS : skill.name);
  const ShowPainel = () => (
    <Ul>
      {technologies.map((tech, i) => (
        <Technology
          key={i}
          pageLanguage={props.pageLanguage}
          technology={tech}
          totalProjects={projects}
          navigate={() => props.navigate(tech.name.toLowerCase())}
        />
      ))}
    </Ul>
  );

  useLayoutEffect(() => {
    if (showPagination && heightDashboard === 0) {
      const height = BrowserUtils.getHeihElementById(props.idCss);
      height > 0 && setHeightDashboard(height);
    }
  }, [showPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TransitionEffect>
      <div id={props.idCss} className="Board-skillpanel" style={{ minHeight: minHeightForDashboard }}>
        <InfoSkills className="Board-skillpanel-info">
          {TEXT_INFO_SKILL_DASHBORD + " "} <b>{getSkillName(props.skill) + "  "}</b>(<span>{projects}</span>)
        </InfoSkills>

        {showPagination && (
          <Pagination
            className="Board-skillpanel-pagination"
            elementsPerPage={MAX_TECHNOLOGIES_PER_BOARD}
            handleClick={handleClickPagination}
            totalElements={totalTechnologies}
            showPagination={showPagination}
            page={page}
          />
        )}

        <ShowPainel />
      </div>
    </TransitionEffect>
  );
};

const DIFFERENCE_HEADER_IN_PIXELS = 65;

export default PanelSkill;

const InfoSkills = styled(SubTopic as any)`
  text-align: center;
  margin: 0;

  @media (max-width: 600px) {
    margin: 0 2%;
  }

  span {
    color: ${({ theme }) => theme.colors.text.title};
    font-weight: bold;
    margin: 0 1px;
  }
`;

const Ul = styled.ul`
  min-height: inherit !important;

  @media (max-width: 600px) {
    margin: 10px 0px;
  }
`;
