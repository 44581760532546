import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';

const colorSelected = css`
  color: ${({ theme }) => theme.colors.primary.light} !important;
  background-color: inherit !important;
  svg,
  path {
    fill: ${({ theme }) => theme.colors.primary.light} !important;
  }
`;

export const Container = styled.nav`
  .MuiTreeItem-root {
    .MuiTreeItem-label {
      font-size: 1em;
    }

    .Expanded-true {
      margin-left: 0 !important;
    }
    .Menu-nav-item-text,
    .MuiSvgIcon-root {
      font-size: 0.8em;
    }

    .Global-icon-svg > .MuiSvgIcon-root {
      opacity: 0.7;
    }
    .Global-icon-svg > .Menu-nav-item-text {
      margin-left: 8%;
    }
    .MuiTreeItem-content {
      padding: 0.1em 0em;

      .Menu-nav-item-text,
      .Menu-nav-item-text-info {
        margin-bottom: 0%;
      }

      .Menu-nav-item-text-info {
        font-style: italic;
        margin-left: auto;
      }
    }
    .MuiTreeItem-group {
      padding-left: 10% !important;
    }
  }

  .MuiTreeItem-root:hover > .MuiTreeItem-content,
  .MuiTreeItem-root:focus > .MuiTreeItem-content,
  .Mui-selected > .MuiTreeItem-content,
  .Mui-expanded > .MuiTreeItem-content {
    .MuiTreeItem-iconContainer,
    .MuiTreeItem-label {
      ${colorSelected}
    }
  }

  .Mui-selected > div {
    .MuiSvgIcon-root {
      ${colorSelected}
    }
  }

  .Mui-selected {
    color: ${({ theme }) => theme.colors.text.paragraph} !important;
  }
`;

export const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    label: {
      fontWeight: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.3, 0),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  })
);
