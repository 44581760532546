import styled from "styled-components";

export const Container = styled.span`
  width: 100%;

  .Button-how-its-work {
    margin-bottom: 2vh;
  }

  #Projects-filter-info {
    grid-area: filterInfo;
    margin: 5px;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  #Projects-filter-company {
    grid-area: filterCompany;
  }

  #Projects-filter-year {
    grid-area: filterYear;
  }

  #Projects-pagination {
    grid-area: pagination;
    width: fit-content;
    justify-self: center;
    width: 100%;
  }

  #Projects-filter-tech {
    grid-area: filterTech;

    .MuiTextField-root {
      width: 100%;
    }
    input {
      padding-top: 20px;
      padding-bottom: 15.5px;
    }
  }

  #Projects-orderby-select {
    grid-area: orderby;

    .MuiSelect-select {
      font-size: 0.7em;
      padding-bottom: 2px;
    }
  }

  #Projects-header {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "filterTech filterTech filterTech filterTech"
      "filterYear filterCompany filterCompany filterInfo"
      "orderby pagination pagination none";

    grid-gap: 20px 20px;
    margin-bottom: 20px;
    align-items: center;
  }

  #Projects-list {
    display: flex;
    flex-flow: wrap;
    align-items: center;
  }

  .Global-pagination > ul {
    justify-content: center;
  }

  #Projects-last-pagination {
    margin: 5% 0%;
  }

  @media (max-width: 1250px) {
    #Projects-header {
      grid-template-columns: 30% 40% auto;
    }
  }

  @media (max-width: 680px) {
    .Cards {
      margin: 1% 0;
    }

    #Projects-header {
      grid-template-areas:
        "filterTech filterTech filterTech"
        "filterYear filterCompany filterCompany"
        "orderby filterInfo filterInfo"
        "pagination pagination pagination";
    }
    #Projects-filter-info {
      justify-content: start;
    }
  }

  @media (max-width: 600px) {
    #Projects-header {
      grid-template-areas:
        "filterTech filterTech filterTech"
        "filterYear filterCompany filterCompany"
        "orderby filterInfo filterInfo"
    }
  }

  @media (max-width: 420px) {
    #Projects-header {
      grid-template-areas:
        "filterTech filterTech filterTech"
        "filterCompany filterCompany filterCompany"
        "filterYear filterYear filterYear"
        "orderby orderby orderby"
        "filterInfo filterInfo filterInfo";
    }
  }

  @media (max-width: 340px) {
    #Projects-header {
      #Projects-filter-company,
      #Projects-filter-year {
        width: 100%;
      }
    }
  }
`;
