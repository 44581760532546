import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { DefaultTheme } from 'styled-components';

const PRIMARY_LIGHT = '#ff7518';
const PRIMARY_MAIN = '#fd5f21';
const PRIMARY_DARK = '#f54703';
const TEXT_SUBTITLE = '#d69870B3';
const BACKGROUND_PRIMARY = '#232628';
const BACKGROUND_SECONDARY = '#1b1b1b';
const TEXT_PRIMARY = 'rgb(255, 255, 255, 0.9)';
const TEXT_SECONDARY = 'rgb(255, 255, 255, 0.6)';
const DISABLED = 'rgb(255, 255, 255, 0.35)';
const BORDER = '#70685C';
const HOVER = 'rgb(255, 255, 255, 0.08)';
const BACKGROUND_DISABLED = 'rgb(0, 0, 0, 0.7)';

/* Gradiente for menu has be rgb with copy color of background */
const GRADIENT_EFFECT_START = 'rgb(27, 27, 27, 1)';
const GRADIENT_EFFECT_END = 'rgb(27, 27, 27, 0.1)';

const primary = {
  light: PRIMARY_LIGHT,
  main: PRIMARY_MAIN,
  dark: PRIMARY_DARK,
};

const styled = (color30Percent: string): DefaultTheme => ({
  title: 'dark',
  colors: {
    primary,

    primaryAux: PRIMARY_DARK,

    disable: DISABLED,

    background: {
      primary: BACKGROUND_PRIMARY,
      primaryStrong: '#34393b',
      secondary: BACKGROUND_SECONDARY,
      border: BORDER,
      hover: HOVER,
      gradientEffectStart: GRADIENT_EFFECT_START,
      gradientEffectEnd: GRADIENT_EFFECT_END,
      backgroundDisabled: BACKGROUND_DISABLED,
    },

    text: {
      title: PRIMARY_MAIN,
      subTitle: TEXT_SUBTITLE,
      paragraph: TEXT_PRIMARY,
      paragraphSecondary: TEXT_SECONDARY,
      hyperlink: '#6aa5ff',
    },
  },
});

const material: PaletteOptions = {
  type: 'dark',
  text: {
    primary: TEXT_PRIMARY,
    secondary: TEXT_SECONDARY,
    disabled: DISABLED,
  },
  primary,
  background: {
    paper: BACKGROUND_PRIMARY,
    default: BACKGROUND_SECONDARY,
  },
  divider: BORDER,
  action: {
    hover: HOVER,
    disabled: 'rgb(255, 255, 255, 0.1)',
  },
};

export default {
  styled,
  material,
};
