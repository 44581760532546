import styled from 'styled-components';

import { cssButtonSelected } from '../../../../styles/cssGlobalCustom';

export const Container = styled.ul<{ disableButtonTurnBack: boolean }>`
  list-style-type: none;
  align-self: start;

  .Menu-buttons {
    width: 100%;
    justify-content: start;
  }

  .Button-turnback {
    color: ${({ theme }) => theme.colors.primary.dark};
    svg {
      fill: ${({ theme }) => theme.colors.primary.dark};
    }

    ${({ disableButtonTurnBack }) => (disableButtonTurnBack ? 'animation: none !important;' : '')}
  }

  .Selected-true,
  .Menu-nav-buttons-li:hover {
    ${cssButtonSelected}
  }
`;
