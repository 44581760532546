import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Dispatch, bindActionCreators } from 'redux';

import { ROUTES } from '../../constants/routesConstants';
import { PageLanguage, RouteType } from '../../constants/types';
import { ApplicationState } from '../../store';
import { navigate } from '../../store/ducks/menu';
import { setTimeoutOnClick } from '../../Utils';
import BrowserUtils from '../../Utils/BrowserUtils';
import RouterUtils from '../../Utils/RouterUtils';
import StringUtils from '../../Utils/StringUtils';

import AboutMe from './AboutMe/AboutMePage';
import Blog from './Blog';
import EducationPage from './Education/EducationPage';
import JobsPage from './Jobs/JobsPage';
import ProjectDatails from './ProjectDetails';
import Projects from './Projects/ProjectsPage';
import SkillsPage from './Skills/SkillsPage';

type StateProps = {
  pageLanguage: PageLanguage;
  menuId: string;
  menuPath?: string;
};

type OwnProps = {
  firstPath: string;
  firstRouteId: string;
};

type DispatchProps = {
  navigate: (route: RouteType) => void;
};

type Props = StateProps & OwnProps & DispatchProps;

const RouterCommonPages: React.FC<Props> = ({ firstPath, firstRouteId, menuId, menuPath = '', navigate, pageLanguage }) => {
  const isNewRouteFromHomePage = StringUtils.isNullOrEmpty(menuId);
  const path = isNewRouteFromHomePage ? firstPath : menuPath;
  const routeId = isNewRouteFromHomePage ? firstRouteId : menuId;

  if (isNewRouteFromHomePage) {
    setTimeoutOnClick(() => navigate({ id: routeId, path, name: '' }));
    return <Fragment />;
  }

  switch (routeId) {
    case ROUTES.WORK_EXPERIENCE_JOBS.id:
      return componentWithEffectScrollUp(<JobsPage pageLanguage={pageLanguage} />);
    case ROUTES.WORK_EXPERIENCE_JOBS_JOB_DETAILS.id:
      return <JobsPage showJob={RouterUtils.getIdInUrl(path)} pageLanguage={pageLanguage} />;
    case ROUTES.WORK_EXPERIENCE_PROJECTS.id:
    case ROUTES.WORK_EXPERIENCE_PROJECTS_FILTER.id:
      return componentWithEffectScrollUp(<Projects filters={RouterUtils.getQueryComponentsFromUrl(path)} />);
    case ROUTES.WORK_EXPERIENCE_PROJECTS_DETAILS.id:
      return componentWithEffectScrollUp(<ProjectDatails projectId={RouterUtils.getIDFromLastPath(path)} />);
    case ROUTES.WORK_EXPERIENCE_SKILLS.id:
      return componentWithEffectScrollUp(<SkillsPage pageLanguage={pageLanguage} />);
    case ROUTES.BLOG.id:
      return componentWithEffectScrollUp(<Blog />);
    case ROUTES.BLOG_POST.id:
      return componentWithEffectScrollUp(<Blog postId={RouterUtils.getIDFromLastPath(path)} />);
    case ROUTES.ABOUT_ME.id:
      return componentWithEffectScrollUp(<AboutMe pageLanguage={pageLanguage} />);
    case ROUTES.EDUCATION.id:
    case ROUTES.EDUCATION_COLLEGES.id:
    case ROUTES.EDUCATION_COURSES.id:
    case ROUTES.EDUCATION_LANGUAGES.id:
    case ROUTES.EDUCATION_RECOGNITIONS.id:
      return <EducationPage routeId={routeId} />;
    default:
      setTimeoutOnClick(() => navigate({ path: '', id: '', name: '' }));
      return <Redirect to={ROUTES.HOME.path} />;
  }
};

const componentWithEffectScrollUp = (component: JSX.Element) => {
  BrowserUtils.scrollUp();
  return component;
};

const mapStateToProps = (state: ApplicationState) => ({
  pageLanguage: state.pageLanguage,
  menuId: state.menu.route.id,
  menuPath: state.menu.route.path,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ navigate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RouterCommonPages);
