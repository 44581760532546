import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import {
  Face as FaceIcon,
  RoomOutlined as LocationIcon,
  Phone as PhoneIcon,
  MailOutline as EmailIcon
} from '@material-ui/icons';

import { Divider, LinkButton as ButtonMyHistory } from '../..';
import { PagesTranslations } from '../../../assets/translations';
import { YEARS_AS_SOFTWARE_DEVELOPER } from '../../../constants/constants';
import { PageLanguage, Profile, DisplayType } from '../../../constants/types';
import { ApplicationState } from '../../../store';
import DisplayUtils from '../../../Utils/DisplayUtils';
import { SubtitleMedium as Position, SubTopic as TopicsInfo } from '../../Typography';

import { TopicsLi, Particulars, Characters } from './styles';

type StateProps = {
  displayType: DisplayType;
};

type Props = {
  ageNumber: string;
  isSidePosition: boolean;
  navigate?: () => void;
  pageLanguage: PageLanguage;
  profile: Profile;
};

const Introduction: React.FC<StateProps & Props> = props => {
  const { ageNumber, isSidePosition, pageLanguage, profile } = props;
  const { TEXT_AGE, TEXT_BUTTON_MY_HISTORY, TEXT_INTRO } = PagesTranslations[pageLanguage].HOME.profile;
  const TEXT_FULL_AGE = `${ageNumber} ${TEXT_AGE}`;

  const textIntroSplitted = TEXT_INTRO.split('_italic_');
  const TEXT_INTRO_1 = textIntroSplitted[0];
  const TEXT_INTRO_ITALIC = textIntroSplitted[1];
  const TEXT_INTRO_2 = textIntroSplitted[2];

  const isMobilePhone = DisplayUtils.isMobilePhone(props.displayType);

  const Age = () => (
    <TopicsLi className="Profile-particulars-info-topics">
      <FaceIcon className="Global-icon-svg" />
      <TopicsInfo className="Topics-info">{TEXT_FULL_AGE}</TopicsInfo>
    </TopicsLi>
  );

  const Location = () => (
    <TopicsLi className="Profile-particulars-info-topics">
      <LocationIcon className="Global-icon-svg" />
      <TopicsInfo className="Topics-info">{profile.location}</TopicsInfo>
    </TopicsLi>
  );

  const Phone = () => (
    <TopicsLi className="Profile-particulars-info-topics">
      <PhoneIcon className="Global-icon-svg" />
      <TopicsInfo className="Topics-info">{profile.phone}</TopicsInfo>
    </TopicsLi>
  );

  const Email = () => (
    <TopicsLi className="Profile-particulars-info-topics">
      <EmailIcon className="Global-icon-svg" />
      <TopicsInfo className="Topics-info Topic-email">
        {<a href={`mailto:${profile.email}`}>{profile.email}</a>}
      </TopicsInfo>
    </TopicsLi>
  );

  const TextIntro = () => (
    <Fragment>
      <Divider position={isMobilePhone ? 'horizontal' : 'vertical'} />
      <summary id="Profile-particulars-intro" style={{ display: 'inherit' }}>
        <p>
          {TEXT_INTRO_1} <i>{TEXT_INTRO_ITALIC.replace('#YEAR#', YEARS_AS_SOFTWARE_DEVELOPER)}</i> {TEXT_INTRO_2}
        </p>

        <ButtonMyHistory handleClick={props.navigate} position="end" text={TEXT_BUTTON_MY_HISTORY} />
      </summary>
    </Fragment>
  );

  return (
    <Particulars id="Profile-particulars">
      <Characters id="Profile-particulars-info">
        <Position id="Profile-particulars-position" fontWeight="normal">
          {profile.position}
        </Position>
        <ul>
          <Age />
          <Phone />
          <Email />
          <Location />
        </ul>
      </Characters>

      {!isSidePosition && <TextIntro />}
    </Particulars>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type
});

export default connect(mapStateToProps)(Introduction);
