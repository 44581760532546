import React from 'react';

import { PagesTranslations } from '../../../../assets/translations';
import { Divider } from '../../../../components';
import Colleges from '../../../../components/Colleges/CollegesContainer';
import { ROUTES } from '../../../../constants/routesConstants';
import { PageLanguage, RouteType } from '../../../../constants/types';
import HeaderTitle from '../HeaderTitle';

import CollegesView from './CollegesView';

type Props = {
  idCss: string;
  navigate: (route: RouteType) => void;
  pageLanguage: PageLanguage;
};

const Education: React.FC<Props> = ({ idCss, navigate, pageLanguage }) => {
  const { TEXT_TITLE } = PagesTranslations[pageLanguage].HOME.education;
  const navigateEducationPage = () => navigate({ ...ROUTES.EDUCATION_COLLEGES });

  return (
    <section id={idCss} className="Global-paper">
      <HeaderTitle title={TEXT_TITLE} onClick={navigateEducationPage} />
      <Divider />
      <Colleges ComponentView={CollegesView} />
    </section>
  );
};

export default Education;
