import React, { Fragment } from "react";

import styled from "styled-components";

import { Spotlight } from "../../constants/types";
import ListUL from "../../pages/home/components/ListUL";
import PrintPageContent from "../PrintPageContent";
import { LessFocusSpan, SubtitleSmall as Text, Topic } from "../Typography";

type Props = {
  highlights: Array<Spotlight>;
  idCss?: string;
  className?: string;
  isHomePage?: boolean;
};

const HighlightsView: React.FC<Props> = ({ className = "", highlights, idCss = "", isHomePage }) => {
  if (isHomePage) {
    return <HomeListComponent idCss={idCss} children={buildHighlightsLi(highlights, className)} totalElements={highlights?.length} />;
  }

  return (
    <article>
      {highlights.map((spotlight, i) => (
        <Section key={i}>
          <Topic className="Awards-title" style={{ margin: "0" }}>
            {spotlight.name}
          </Topic>
          <br />
          {spotlight.content && (
            <Fragment>
              <PrintPageContent pageContent={spotlight.content} />
              <br />
              <br />
            </Fragment>
          )}
        </Section>
      ))}
    </article>
  );
};

export default HighlightsView;

const buildHighlightsLi = (items: Array<Spotlight>, className: string): Array<JSX.Element> => {
  const smallListToHome: Array<JSX.Element> = [];

  if (items) {
    for (let i = 0; i < 3; i++) {
      smallListToHome.push(
        <Text className={className} key={i}>
          {items[i]?.name}
        </Text>
      );
    }
  }

  return smallListToHome;
};

const HomeListComponent = (props: { idCss: string; children: Array<JSX.Element>; totalElements: number }) => (
  <Fragment>
    <ListUL idCss={props.idCss} children={props.children} />
    {props.totalElements > 3 && (
      <p style={{ textAlign: "center", fontFamily: "monospace" }}>
        <LessFocusSpan>...({props.totalElements})</LessFocusSpan>
      </p>
    )}
  </Fragment>
);

const Section = styled.section`
  .Awards-title {
    color: ${({ theme }) => theme.colors.primary.light};
  }
`;
