import { AnyAction } from "redux";

import { College, PageLanguage } from "../../../constants/types";

/*
 * Action Types
 */

export enum Types {
  LOAD_REQUEST = "@colleges/LOAD_REQUEST",
  LOAD_SUCCESS = "@colleges/LOAD_SUCCESS",
  LOAD_FAILURE = "@colleges/LOAD_FAILURE",
}

export type State = {
  readonly data: {
    EN: Array<College>;
    ES: Array<College>;
    PT: Array<College>;
    FR: Array<College>;
  };
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  pageLanguage: PageLanguage;
  colleges: Array<College>;
};

export interface Action extends AnyAction {
  payload: Payload;
}
