import React, { useState } from 'react';

import styled from 'styled-components';

import { PagesTranslations, AppTranslations } from '../../../../assets/translations';
import { Jobs, Divider, LinkButton as ButtonShowMore } from '../../../../components';
import { ROUTES } from '../../../../constants/routesConstants';
import { PageLanguage, RouteType } from '../../../../constants/types';
import { GlobalEffectRipple } from '../../../../styles/cssGlobalCustom';
import BrowserUtils from '../../../../Utils/BrowserUtils';
import HeaderTitle from '../HeaderTitle';

type Props = {
  idCss: string;
  idCssNodeBefore: string;
  navigate: (route: RouteType) => void;
  pageLanguage: PageLanguage;
};

const WorkExperience: React.FC<Props> = (props) => {
  const [hiddeButtonShowMore, setHiddeButtonShowMore] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const { TEXT_TITLE } = PagesTranslations[props.pageLanguage].HOME.workExperience;
  const { TEXT_SHOW_LESS, TEXT_SHOW_MORE } = AppTranslations[props.pageLanguage].links;

  const SHOW_SOME_PROJECTS = 3;
  const SHOW_ALL_PROJECTS = -1;

  const TEXT_LINK = showMore ? TEXT_SHOW_LESS : TEXT_SHOW_MORE;
  const totalProjects = showMore ? SHOW_ALL_PROJECTS : SHOW_SOME_PROJECTS;

  const handleViewButtonShowMore = (jobsLength: number) => jobsLength > SHOW_SOME_PROJECTS && setHiddeButtonShowMore(false);
  const navigateWorkExperiencePage = () => props.navigate(ROUTES.WORK_EXPERIENCE_JOBS);

  const handleShowMoreJobs = () => {
    if (showMore) BrowserUtils.scrollUpId(props.idCssNodeBefore);
    setShowMore(!showMore);
  };

  return (
    <Section id={props.idCss} className="Global-paper">
      <HeaderTitle title={TEXT_TITLE} onClick={navigateWorkExperiencePage} />
      <Divider />

      <Jobs homepage viewSomeProjects={totalProjects} callbackJobsLength={handleViewButtonShowMore} />

      <Divider />
      {!hiddeButtonShowMore && <ButtonShowMore noIcon={true} handleClick={handleShowMoreJobs} text={TEXT_LINK} />}
    </Section>
  );
};

export default WorkExperience;

const Section = styled.section`
  display: flex;
  flex-direction: column;

  p {
    font-size: 0.85em !important;
  }

  .Jobs-item {
    .Job-content > p {
      color: ${({ theme }) => theme.colors.disable};
    }
  }

  #Job-0,
  .Jobs-item:hover {
    .Job-content > p {
      color: ${({ theme }) => theme.colors.text.paragraph};
    }
  }

  #Job-0:hover .Job-content > p {
    color: ${({ theme }) => theme.colors.text.subTitle};
  }

  .Jobs-item {
    ${GlobalEffectRipple}
  }
`;
