import React from 'react';

import { IconButton, SvgIconProps } from '@material-ui/core';

import { setTimeoutOnClick } from '../../Utils';

type Props = {
  children?: never;
  className?: string;
  color?: string;
  component?: any;
  href?: string;
  icon: React.ElementType<SvgIconProps>;
  id?: string;
  onClick?: () => void;
  style?: React.CSSProperties | React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
  target?: '_blank';
  disabled?: boolean;
  title?: string;
};

const Button: React.FC<Props> = ({
  disabled = false,
  className = '',
  title,
  style,
  color,
  component,
  href,
  target,
  icon: Icon,
  id = '',
  onClick,
}) => {
  return (
    <IconButton
      disabled={disabled}
      id={id}
      className={className}
      style={{ color: color, ...style }}
      onClick={() => setTimeoutOnClick(onClick)}
      component={component}
      href={href}
      target={target}
      title={title}
    >
      <Icon />
    </IconButton>
  );
};

export default Button;
