import styled, { css } from 'styled-components';

const positionCenterFixedElement = css`
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const headerCircle = css`
  border-radius: 0 0 30vh 30vh;
  width: 80%;
`;

const disableHeaderCircle = css`
  border-radius: inherit;
  width: 100%;
`;

const menuSideBarFullHeight = css`
  @media (max-height: 700px) and (max-width: 1024px) {
    margin-left: 8vw;
    width: 92vw;

    @media (max-width: 940px) {
      & {
        margin-left: 8.5vw;
        width: 91.5vw;
      }
    }
    @media (min-width: 500px) and (max-width: 680px) {
      & {
        margin-left: 10.5vw;
        width: 89.5vw;
      }
    }
    @media (max-width: 680px) and (max-height: 640px), (max-height: 400px) {
      & {
        height: auto;
        min-height: auto;
      }
    }
  }

  @media (max-height: 840px) and (min-width: 1025px) {
    @media (min-width: 2800px) {
      & {
        max-width: 1400px;
      }
    }
    @media (max-width: 2800px) {
      & {
        max-width: 1200px;
        padding: 0;
      }
    }
    @media (max-width: 2400px) {
      & {
        margin-left: 25%;
      }
    }
    @media (min-width: 1600px) {
      & {
        ${headerCircle}
      }
    }
    @media (max-width: 1600px) {
      & {
        max-width: none;
        width: 75%;
        margin-left: 25%;
        left: 0;
      }
    }
  }

  @media (min-height: 841px) and (max-height: 880px) and (min-width: 1024px) {
    ${disableHeaderCircle}
  }
`;

const cssStyleSidebar = css`
  ${menuSideBarFullHeight}

  @media (max-width: 500px) {
    margin-left: 0;
    width: 100vw;
  }
`;

const cssStylePosition = css`
  ${positionCenterFixedElement}

  @media (max-width: 1250px) {
    width: 100%;
    left: 0;
  }

  ${({ isHomePage }: any) => (isHomePage ? '' : cssStyleSidebar)}
`;

const lightColor = css`
  &,
  #Header-menu > button .MuiSvgIcon-root,
  #Header-theme .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.background.primary} !important;
  }

  .MuiIconButton-root:hover {
    background: ${({ theme }) => theme.colors.background.hover} !important;
  }
`;

export const Container = styled.header<{ isHomePage: boolean }>`
  background: ${({ theme }) => (theme.title === 'light' ? theme.colors.primaryAux : theme.colors.background.secondary)};

  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

  height: 8vh;
  min-height: 75px;
  max-height: 130px;
  position: fixed;
  top: 0;
  padding: 0% 5%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;

  #Header-menu,
  #Header-social-media {
    grid-area: navigation;
  }
  #Header-theme {
    grid-column-start: 2;
    grid-area: theme;
  }
  #Header-language {
    grid-area: language;
    .MuiSelect-select {
      color: ${({ theme }) => (theme.title === 'light' ? theme.colors.background.primary : 'revert')} !important;
    }
    .MuiSvgIcon-root {
      color: ${({ theme }) => (theme.title === 'light' ? theme.colors.background.secondary : 'inherit')} !important;
    }
  }

  grid-template-areas: 'navigation theme language';

  @media (max-width: 1250px) {
    ${headerCircle}
  }

  ${cssStylePosition}

  ${({ theme }) => (theme.title === 'light' ? lightColor : '')}
`;
