import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import { PageLanguage } from '../../../constants/types';
import Api from '../../../services/api';

import { loadSuccess, loadFailure } from './actions';

export interface Payload extends AnyAction {
  payload: PageLanguage;
}

export function* loadProfile(action: Payload) {
  try {
    const pageLanguage = action.payload;
    const response = yield Api.getProfile(pageLanguage);

    yield put(loadSuccess({ pageLanguage, profile: response }));
  } catch (err) {
    yield put(loadFailure());
  }
}
