import { action } from 'typesafe-actions';

import { PageLanguage, Workplace } from '../../../constants/types';

import { Types, Payload } from './types';

export const loadRequest = (pageLanguage: PageLanguage, workplace: Workplace) => action(Types.LOAD_REQUEST, { pageLanguage, workplace });
export const loadSuccess = (data: Payload) => action(Types.LOAD_SUCCESS, data);
export const loadFailure = () => action(Types.LOAD_FAILURE);
export const changeWorkplace = (workplace: Workplace) => action(Types.CHANGE_WORKPLACE, { workplace });
