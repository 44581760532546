const isNullOrEmpty = (string: string | undefined): boolean => string === undefined || string === "" || string.length === 0;
const isNotNullOrEmpty = (string: string | undefined): boolean => !isNullOrEmpty(string);

/**
 * Function to return if string contains at list of string
 *
 * @param {string} string:
 * @param {Array<string>} strings
 * @returns {boolean}
 */
export const containsAtList = (string: string, strings: Array<string>): boolean => {
  const clearString = (str: string) => str.replace(/\s|-/gm, "").toLowerCase();
  return strings.some((s) => clearString(string).includes(clearString(s)));
};

const StringUtils = {
  isNullOrEmpty,
  isNotNullOrEmpty,
  containsAtList,
};

export default StringUtils;
