import { Reducer } from "redux";

import { PageLanguage } from "../../../constants/types";

import { Types, State, Action } from "./types";

const INITIAL_STATE: State = {
  data: {
    EN: {
      jobs: [],
      github: [],
      both: [],
    },
    ES: {
      jobs: [],
      github: [],
      both: [],
    },
    PT: {
      jobs: [],
      github: [],
      both: [],
    },
    FR: {
      jobs: [],
      github: [],
      both: [],
    },
  },
  loading: false,
  error: false,
};

const ProjectsReducer: Reducer<State, Action> = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.LOAD_REQUEST:
      return loadRequest(state);
    case Types.LOAD_SUCCESS:
      return loadSuccess(state, action);
    case Types.LOAD_FAILURE:
      return loadFailure(state);
    default:
      return state;
  }
};

/*
 * Handlers
 */
const loadSuccess = (state: State, action: Action): State => ({
  ...state,
  loading: false,
  error: false,
  data: {
    ...state.data,
    [action.payload.pageLanguage]: {
      ...state.data[action.payload.pageLanguage as PageLanguage],
      [action.payload.workplace]: action.payload.projects,
    },
  },
});
const loadRequest = (state: State): State => ({
  ...state,
  loading: true,
  error: false,
});
const loadFailure = (state: State): State => ({
  ...state,
  loading: false,
  error: true,
});

export default ProjectsReducer;
