import BrowserUtils from '../../Utils/BrowserUtils';
import StringUtils from '../../Utils/StringUtils';

import { JobFromHomePage, ScrollUpToJobID, GetJobs, NewStateFromHomePage } from './types';

const INITIAL_FIRST_JOB = 0;

const isNewStateFromHomePage: NewStateFromHomePage = (jobIdSelected, stateShowJob) =>
  !StringUtils.isNullOrEmpty(jobIdSelected) && stateShowJob === INITIAL_FIRST_JOB;

const getJobs: GetJobs = (jobs, viewOnlyTotal) => (viewOnlyTotal && viewOnlyTotal > 0 ? jobs.slice(0, viewOnlyTotal) : jobs);

const openJobFromHomePage: JobFromHomePage = (jobs, jobIdSelected, setOpenJobIndex, setStateJobIdSelected) => {
  const openNewJob = jobs.findIndex((job) => job.id === jobIdSelected);
  if (openNewJob > INITIAL_FIRST_JOB) {
    setOpenJobIndex(openNewJob);
    setStateJobIdSelected('');
  }
};

const moveToScrollJobID: ScrollUpToJobID = (currentJobIndex, isOpenFullContet, displayType) => {
  if (isOpenFullContet || displayType === 'smd') {
    const refJobBeforeToScrollView = 2;
    const isfirstElementsMoveScrollWindowToUp = currentJobIndex < refJobBeforeToScrollView;
    if (isfirstElementsMoveScrollWindowToUp) window.scrollTo(0, 100);
    else BrowserUtils.scrollUpId(`Job-${currentJobIndex - refJobBeforeToScrollView}`);
  }
};

export { isNewStateFromHomePage, getJobs, moveToScrollJobID, openJobFromHomePage };
