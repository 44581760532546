import { PageLanguage } from '../constants/types';

type FullDate = {
  startMonth: string;
  finalMonth: string;
  startDate: Date;
  finalDate: Date;
};

const monthNames = {
  EN: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  ES: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  PT: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  FR: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
};

const getFullDateWithTranslationMonths = (pageLanguage: PageLanguage, startDate: Date, finalDate: Date, prefix?: boolean): FullDate => {
  let startMonth = monthNames[pageLanguage][startDate.getMonth()];
  let finalMonth = monthNames[pageLanguage][finalDate.getMonth()];
  if (prefix) {
    startMonth = startMonth ? startMonth.substring(0, 3) : '';
    finalMonth = finalMonth ? finalMonth.substring(0, 3) : '';
  }
  return {
    startMonth,
    finalMonth,
    startDate,
    finalDate,
  };
};

const getDateTranslate = (pageLanguage: PageLanguage, date: Date) => {
  const month = monthNames[pageLanguage][date.getMonth()];
  return `${month} ${date.getUTCDate()}, ${date.getFullYear()}`;
};

const getFormattedFullDate = (fullDate: FullDate) => {
  if (fullDate.startDate.getTime() === fullDate.finalDate.getTime()) return `${fullDate.finalMonth} ${fullDate.finalDate.getFullYear()}`;

  return fullDate.startDate.getFullYear() === fullDate.finalDate.getFullYear()
    ? `${fullDate.startMonth} - ${fullDate.finalMonth} ${fullDate.finalDate.getFullYear()}`
    : `${fullDate.startMonth} ${fullDate.startDate.getFullYear()} - ${fullDate.finalMonth} ${fullDate.finalDate.getFullYear()}`;
};

const getMonthYear = (pageLanguage: PageLanguage, date: Date): string => {
  const month = monthNames[pageLanguage][date.getMonth()];
  return `${month}/${date.getFullYear()}`;
};

export default {
  getFullDateWithTranslationMonths,
  getFormattedFullDate,
  getMonthYear,
  getDateTranslate,
};
