import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Fab as TurnBackButtonSmallDevices } from '@material-ui/core';
import { bindActionCreators, Dispatch } from 'redux';

import { ChatButton, TransitionEffect } from '../../components';
import { BackIcon } from '../../components/Icons';
import DrawerMenu from '../../components/Navigation/DrawerMenu';
import { DisplayType, RouteType } from '../../constants/types';
import { ApplicationState } from '../../store';
import { returnPreviousRoute, navigate } from '../../store/ducks/menu';
import DisplayUtils from '../../Utils/DisplayUtils';

import RouterCommonPages from './RouterCommonPages';
import { Main } from './styles';

type StateProps = {
  displayType: DisplayType;
  hasHistory: boolean;
};

type DispatchProps = {
  returnPreviousRoute: () => void;
  navigate: (route: RouteType) => void;
};

type OwnProps = {
  currentUrl: string;
  routeId: string;
};

type Props = StateProps & DispatchProps & OwnProps;

const CommonPages: React.FC<Props> = (props) => {
  const [chatOpen, setChatOpen] = useState(false);
  const isMobilePhone = DisplayUtils.isMobilePhoneSM(props.displayType);

  const onClickChat = () => setChatOpen((prevState) => !prevState);

  const TurnBackButton = () => (
    <TurnBackButtonSmallDevices id="Commonpage-article-button-back" onClick={props.returnPreviousRoute}>
      <BackIcon />
    </TurnBackButtonSmallDevices>
  );

  return (
    <Main chatOpen={chatOpen}>
      {!isMobilePhone && (
        <aside>
          <DrawerMenu navigate={props.navigate} />
        </aside>
      )}

      <TransitionEffect>
        <article id="Commonpage-article">
          {isMobilePhone && props.hasHistory && <TurnBackButton />}
          <RouterCommonPages firstPath={props.currentUrl} firstRouteId={props.routeId} />
          <br />
        </article>
      </TransitionEffect>
      {chatOpen && <ChatButton onClick={onClickChat} />}
    </Main>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type,
  hasHistory: state.menu.hasHistory,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ returnPreviousRoute, navigate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CommonPages);
