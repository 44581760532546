import styled from 'styled-components';

import { COLOR_30_PERCENT } from '../../../styles/themes';

import { mediaQueries } from './components/styles/stylesMediaQueries';
import { TransitionEffectOpen, TransitionEffectClose } from './components/styles/stylesTransitionEffect';

type StateContainer = {
  openDrawer: boolean;
};

export const Container = styled.section<StateContainer>`
  display: flex;

  #Commonpage-aside-drawer {
    ${({ openDrawer }) => (openDrawer ? TransitionEffectOpen : TransitionEffectClose)}

    .MuiPaper-root {
      margin-top: 7.7vh;

      margin-top: 10vh;
      height: auto;
      max-height: 82.4vh;
      overflow-x: hidden;
      overflow-y: hidden;



      ${({ openDrawer }) => (openDrawer ? TransitionEffectOpen : TransitionEffectClose)}
    }

    .MuiPaper-root {
      border-left: none !important;
      background: ${({ theme }) => theme.colors.background.secondary};
      @media (max-width: 1024px) {
        background: ${({ theme }) => (theme.title === 'light' ? theme.colors.background.primary : '')};
      }
    }

    #Drawer-content {
      border-left: solid 1px ${({ theme }) => theme.colors.background.border.concat(COLOR_30_PERCENT)};
    }

    #Drawer-content {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      width: fit-content;
      padding: 0% 10%;
      margin: 1.8vh 0vw;

      #Profile-avatar {
        margin-left: 0px;
      }
      #Profile-info {
        grid-template-columns: 100%;
        width: 70%;
        min-width: fit-content;
        text-align: center;
      }
      #Profile-title {
        font-size: 1.45em;
      }

      #Profile-particulars-info {
        font-size: 1.05rem;
      }

      .Profile-error {
        margin-left: 5%;
        justify-self: center;
        align-self: center;
        height: inherit;
        width: 80%;
      }
    }

    #Drawer-toolbar-languages,
    #Drawer-toolbar-theme,
    #Drawer-footer {
      display: none;
    }
  }

  ${mediaQueries}
`;

export const HiddenBackground = styled.span`
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.background.backgroundDisabled};
`;
