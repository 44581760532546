import React, { Fragment } from 'react';

import { Dialog } from '@material-ui/core';
import styled, { css } from 'styled-components';

import Button from '../Buttons/Button';
import { ArrowBefore, ArrowNext, CloseIcon } from '../Icons';

type PropsImageViewDialog = {
  images?: Array<string>;
  description?: string;
  oneimage?: boolean;
};

type State = {
  open: boolean;
  index: number;
};

const ImageViewDialog: React.FC<PropsImageViewDialog> = ({ oneimage, images = [], description }) => {
  const [state, setState] = React.useState<State>({ open: false, index: 0 });

  const firstElement = state.index === 0;
  const lastElement = state.index >= images.length - 1;

  const open = (index: number) => setState({ open: true, index });
  const close = () => setState((prev) => ({ ...prev, open: false }));
  const next = () => setState((prev) => ({ ...prev, index: prev.index + 1 }));
  const back = () => setState((prev) => ({ ...prev, index: prev.index - 1 }));

  const keyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.cancelable) event.preventDefault();
    if (!lastElement && (event.keyCode === FORWARD_KEY.keycode || event.key === FORWARD_KEY.key)) next();
    else if (!firstElement && (event.keyCode === BACKWARD_KEY.keycode || event.key === BACKWARD_KEY.key)) back();
  };

  return (
    <Fragment>
      <ImagesGroup fullsize={oneimage}>
        {images.map((src, i) => (
          <figure key={src + i} style={{ textAlign: 'center', display: 'inherit', placeContent: 'center' }}>
            <Image src={src} alt={description} onClick={() => open(i)} />
          </figure>
        ))}
      </ImagesGroup>

      <Dialog className="Global-imageview-dialog" open={state.open} onClose={close} onKeyUpCapture={keyboardEvent}>
        <ContentDialog>
          <Button icon={ArrowBefore} onClick={back} style={{ visibility: firstElement ? 'hidden' : 'visible' }} />

          <figure>
            <Button icon={CloseIcon} onClick={close} />
            {images.map((src, i) => (
              <Fragment key={src + i}>{state.index === i && <Image key={src + i + i} src={src} alt={description} />}</Fragment>
            ))}
          </figure>

          <Button icon={ArrowNext} onClick={next} style={{ visibility: lastElement ? 'hidden' : 'visible' }} />
        </ContentDialog>
      </Dialog>
    </Fragment>
  );
};

const FORWARD_KEY = {
  keycode: 39,
  key: 'ArrowRight',
};

const BACKWARD_KEY = {
  keycode: 37,
  key: 'ArrowLeft',
};

export default ImageViewDialog;

const smallSizeImage = css`
  width: 30%;
  max-width: 360px;
`;

const ImagesGroup = styled.ul<{ fullsize?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  figure {
    margin: 5px;
    ${({ fullsize }) => (fullsize ? 'max-width: 80%' : smallSizeImage)}

    img {
      max-width: 100%;
    }
  }

  img {
    border-radius: 2px;
  }
`;

const ContentDialog = styled.section`
  display: flex;
  flex-direction: row;
  max-height: inherit;

  button {
    width: fit-content;
    align-self: center;
    color: ${({ theme }) => theme.colors.primary.light} !important;
    background: ${({ theme }) => theme.colors.background.backgroundDisabled};

    :hover {
      background-color: ${({ theme }) => theme.colors.background.backgroundDisabled};
      svg {
        opacity: 0.5;
      }
    }
  }

  figure {
    text-align: center;
  }

  img {
    max-width: none;
    :hover {
      cursor: auto;
    }
  }
`;

const Image = styled.img`
  margin: 2%;
  align-self: center;
  max-width: 70%;

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
