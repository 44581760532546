import React from 'react';

import { MenuButton } from '../../../';
import { AppTranslations } from '../../../../assets/translations';
import { ROUTES } from '../../../../constants/routesConstants';
import { PageLanguage } from '../../../../constants/types';
import {
  AboutMeIcon,
  BlogsIcon,
  TurnBackIcon,
  CollegesIcon,
  CoursesIcon,
  HomeIcon,
  JobsIcon,
  LanguagesIcon,
  ProjetsIcon,
  SkillsIcon,
  RecognitionsIcon,
} from '../../../Icons';

import { Container } from './styles';

type Props = {
  idCss?: string;
  isMobilePhoneSM: boolean;
  pageLanguage: PageLanguage;
  selected: string;
  turnBackDisabledButton: boolean;

  toHome: () => void;
  toAboutMe: () => void;
  toJobs: () => void;
  toPrjs: () => void;
  toSkill: () => void;
  toColleges: () => void;
  toCourses: () => void;
  toLanguages: () => void;
  toRecognitions: () => void;
  toBlogs: () => void;
  turnBack: () => void;
};

/*
 *
 ************ Attention !!! // FIXME
 *
 * If the total number of menu options is even, you have to add an id for the penultimate element - "Menu-nav-buttons-before-last".
 *
 * If total is odd, it is not necessary.
 *
 * Note: Only for mobile devices when presenting a better experience with gradient effect.
 *
 *
 */

const MenuMobile: React.FC<Props> = (props) => {
  const { idCss = '', selected, isMobilePhoneSM } = props;
  const firstButtonId = 'Menu-nav-buttons-first';
  const {
    TEXT_HOME,
    TEXT_ABOUT_ME,
    TEXT_JOBS,
    TEXT_PROJECTS,
    TEXT_SKILLS,
    TEXT_COLLEGES,
    TEXT_COURSES,
    TEXT_LANGUAGES,
    TEXT_RECOGNITIONS,
    TEXT_BLOGS,
  } = AppTranslations[props.pageLanguage].menu;
  const { TEXT_BUTTON_BACK } = AppTranslations[props.pageLanguage].links;

  return (
    <Container id={idCss} className="Global-border-right" disableButtonTurnBack={props.turnBackDisabledButton}>
      {!isMobilePhoneSM && (
        <li id={firstButtonId}>
          <MenuButton
            className={`Menu-nav-buttons-li Button-turnback`}
            text={TEXT_BUTTON_BACK}
            icon={TurnBackIcon}
            onClick={props.turnBack}
            disable={props.turnBackDisabledButton}
          />
        </li>
      )}

      <li id={isMobilePhoneSM ? firstButtonId : ''}>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.HOME.id}`}
          text={TEXT_HOME}
          icon={HomeIcon}
          onClick={props.toHome}
        />
      </li>

      <li>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.ABOUT_ME.id}`}
          text={TEXT_ABOUT_ME}
          icon={AboutMeIcon}
          onClick={props.toAboutMe}
        />
      </li>

      <li>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.WORK_EXPERIENCE_JOBS.id}`}
          text={TEXT_JOBS}
          icon={JobsIcon}
          onClick={props.toJobs}
        />
      </li>

      <li>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.WORK_EXPERIENCE_PROJECTS.id}`}
          text={TEXT_PROJECTS}
          icon={ProjetsIcon}
          onClick={props.toPrjs}
        />
      </li>

      <li>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.WORK_EXPERIENCE_SKILLS.id}`}
          text={TEXT_SKILLS}
          icon={SkillsIcon}
          onClick={props.toSkill}
        />
      </li>

      <li>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.EDUCATION_LANGUAGES.id}`}
          text={TEXT_LANGUAGES}
          icon={LanguagesIcon}
          onClick={props.toLanguages}
        />
      </li>

      <li>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.EDUCATION_COLLEGES.id}`}
          text={TEXT_COLLEGES}
          icon={CollegesIcon}
          onClick={props.toColleges}
        />
      </li>

      <li>
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.EDUCATION_COURSES.id}`}
          text={TEXT_COURSES}
          icon={CoursesIcon}
          onClick={props.toCourses}
        />
      </li>

      <li id="Menu-nav-buttons-before-last">
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.EDUCATION_RECOGNITIONS.id}`}
          text={TEXT_RECOGNITIONS}
          icon={RecognitionsIcon}
          onClick={props.toRecognitions}
        />
      </li>

      <li id="Menu-nav-buttons-last">
        <MenuButton
          className={`Menu-nav-buttons-li Selected-${selected === ROUTES.BLOG.id}`}
          text={TEXT_BLOGS}
          icon={BlogsIcon}
          onClick={props.toBlogs}
        />
      </li>
    </Container>
  );
};

export default MenuMobile;
