import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

import { GlobalEffectRipple } from "../../styles/cssGlobalCustom";

export const ContainerDashboard = styled.section`
  flex-grow: 1;
  width: 100%;

  #Skills-dashboard-header {
    margin-bottom: 0;

    .MuiTab-root {
      min-width: 160px;
    }
    .MuiTabs-flexContainer {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }

  #Skills-dashboard-header-stepps {
    width: fit-content;
    margin-left: auto;
    background: inherit;
    margin-top: 5px;
    .MuiMobileStepper-dots {
      margin-right: 2px;
    }
    .MuiMobileStepper-dotActive {
      background-color: ${({ theme }) => theme.colors.primary.light};
    }
  }

  #Skills-dashboard-header-stepps,
  .Button-how-its-work {
    margin-right: 5%;
  }

  .Button-how-its-work {
    padding-right: revert;
    margin-top: 25px;
  }

  #Skills-dashboard {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 0;

    .Technologies {
      padding: 0 2%;
    }

    .Board-skillpanel {
      display: flex;
      flex-direction: column;

      ul {
        min-height: max-content;
      }

      .Board-skillpanel-pagination {
        align-self: flex-end;
        width: 100%;
        & > ul {
          justify-content: flex-end;
        }
      }
    }
  }

  .MuiTab-root {
    padding: 0;
  }
  .MuiAppBar-root {
    box-shadow: none !important;
  }

  .MuiButtonBase-root {
    color: ${({ theme }) => theme.colors.disable};
  }
  .MuiPagination-ul .Mui-selected {
    color: ${({ theme }) => theme.colors.disable} !important;
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  @media (max-width: 1024px) {
    #Skills-dashboard {
      width: 90%;
    }
  }

  @media (max-width: 600px) {
    #Skills-dashboard-header-stepps,
    .Button-how-its-work {
      margin-right: 0%;
    }
    #Skills-dashboard {
      width: auto;
    }

    #Skills-dashboard-header .MuiTab-root {
      min-width: 100px;
    }

    .Board-skillpanel-info {
      text-align: start;
    }
  }

  @media (max-height: 700px) {
    .Board-skillpanel-info,
    .Button-how-its-work {
      margin-top: 5px;
    }
  }
`;

export const ContainerTechnology = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 20px;

  ${GlobalEffectRipple}

  .Technology-name,
  .Technology-tags {
    padding-left: 1%;
  }

  .Technology-name {
    font-weight: normal;
    margin-bottom: 0%;
    font-size: 0.95rem;
    width: fit-content;
  }

  .Technology-tags {
    font-family: monospace;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
    font-size: 0.7rem;
    margin-bottom: 0;

    :hover {
      cursor: pointer;
    }
  }

  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const TooltipStyleClass = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
}));
