import React, { Fragment } from 'react';

import styled from 'styled-components';

import { AppTranslations, PagesTranslations } from '../../../assets/translations';
import { TransitionEffect, PrintPageContent, TurnBackButton } from '../../../components';
import { Subtitle as Topic, SubtitleSmall as SubTopic } from '../../../components/Typography';
import { Project, PageLanguage, Stack as StackDTO, TechnologyDTO } from '../../../constants/types';
import DateUtils from '../../../Utils/DateUtils';

type Props = {
  project: Project;
  pageLanguage: PageLanguage;
  isMobileDevice: boolean;
  hasHistory: boolean;
  turnBackButton: () => void;
};

const ProjectView: React.FC<Props> = ({ turnBackButton, hasHistory, isMobileDevice, project, pageLanguage }) => {
  const { PROJECT_TIME, ARCHITECTURE_DESIGN_PATERNS, PROJECT_POSITION, SQUAD, TEXT_PRIVATE_PROJECT } = PagesTranslations[
    pageLanguage
  ].PROJECT_DETAILS;
  const { TEXT_COMPANY } = AppTranslations[pageLanguage].info;

  const { documentation } = project;
  const fullDate = DateUtils.getFormattedFullDate(
    DateUtils.getFullDateWithTranslationMonths(pageLanguage, project.projectStart, project.projectEnd)
  );

  const Date = () => (
    <SubTopic className="Topics-label">
      <span className="Topics-label-info">{PROJECT_TIME}</span>
      {fullDate}
    </SubTopic>
  );

  const Company = () => (
    <SubTopic style={{ margin: '5px 0' }}>
      <span className="Topics-label-info">{TEXT_COMPANY}</span>

      <a href={project.company.profileLink} rel="noopener noreferrer" target="_blank" title={project.company.name}>
        {project.company.name}
      </a>
    </SubTopic>
  );

  const Position = () => (
    <SubTopic className="Topics-label">
      <span className="Topics-label-info">{PROJECT_POSITION}</span>
      {project.position}
    </SubTopic>
  );

  const Introduction = () => (
    <Fragment>
      <p>{project.introduction}</p>
      <PrintPageContent pageContent={project.content} />
    </Fragment>
  );

  const Architecture = () => (
    <Fragment>
      <Topic className="Project-topics">{ARCHITECTURE_DESIGN_PATERNS}</Topic>
      <PrintPageContent pageContent={documentation.architecture} />
    </Fragment>
  );

  const StackView = () => documentation.stack && <Stack pageLanguage={pageLanguage} stack={documentation.stack} />;

  const Squad = () => (
    <Fragment>
      <Topic className="Project-topics">{SQUAD}</Topic>
      <ul>
        {project.squad.map((member) => (
          <li key={member.name} className={`Project-topics-${member.isMe}`}>
            {member.position.concat(' - ')}
            <a href={member.profile} rel="noopener noreferrer" target="_blank" title={member.profile}>
              {member.name}
            </a>
          </li>
        ))}
      </ul>
    </Fragment>
  );

  return (
    <TransitionEffect>
      <Container>
        {!isMobileDevice && hasHistory && <TurnBackButton pageLanguage={pageLanguage} onClick={turnBackButton} />}
        {project.cardImageLink && (
          <Figure>
            <img src={project.cardImageLink} alt={project.title} />
          </Figure>
        )}
        <Title>{project.title}</Title>

        <br />
        <Company />
        <Position />
        <Date />
        <br />
        <Introduction />
        <Architecture />
        <StackView />
        <br />
        <Squad />
        <br />
        {project.isPrivate && (
          <p>
            <i>{TEXT_PRIVATE_PROJECT}</i>
          </p>
        )}
      </Container>
    </TransitionEffect>
  );
};

export default ProjectView;

type StackType = {
  stack: StackDTO;
  pageLanguage: PageLanguage;
};

const getTagsList = (name: string, tags: Array<string>) => (
  <ul>
    {tags.map((tag, i) => (
      <li key={name + tag + i}>{tag}</li>
    ))}
  </ul>
);

const Stack: React.FC<StackType> = ({ pageLanguage, stack }) => {
  const { STACK, OTHERS } = PagesTranslations[pageLanguage].PROJECT_DETAILS;
  const getMapValue = (tec: TechnologyDTO) => (
    <Fragment key={tec.name}>
      <li key={tec.name} className="Stack-item">
        {tec.name}
        {tec.tags.length > 0 && getTagsList(tec.name, tec.tags)}
      </li>
    </Fragment>
  );

  return (
    <Fragment>
      <Topic className="Project-topics">{STACK}</Topic>
      <ul id="Project-topics-stack">
        {stack.frontend && (
          <li className="Stack-items">
            <SubTopic className="Stack-items-label">Front-end</SubTopic>
            <ul>{stack.frontend.map(getMapValue)}</ul>
          </li>
        )}

        {stack.backend && (
          <li className="Stack-items">
            <SubTopic className="Stack-items-label">Back-end</SubTopic>
            <ul>{stack.backend.map(getMapValue)}</ul>
          </li>
        )}

        {stack.database && (
          <li className="Stack-items">
            <SubTopic className="Stack-items-label">Database</SubTopic>
            <ul>{stack.database.map(getMapValue)}</ul>
          </li>
        )}

        {stack.cloud && (
          <li className="Stack-items">
            <SubTopic className="Stack-items-label">Cloud</SubTopic>
            <ul>{stack.cloud.map(getMapValue)}</ul>
          </li>
        )}

        {stack.others && (
          <li className="Stack-items">
            <SubTopic className="Stack-items-label">{OTHERS}</SubTopic>
            <ul>{stack.others.map(getMapValue)}</ul>
          </li>
        )}
      </ul>
    </Fragment>
  );
};

const Figure = styled.figure`
  max-width: 40%;
  margin: 5% 10px;
  margin-right: 0;
  float: right;

  img {
    max-width: 100%;
  }

  @media (max-width: 1250px) {
    width: fit-content;
    float: none;
    max-width: 100%;
    text-align-last: center;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 70%;
    }
  }
`;

const Title = styled.h1`
  line-height: 50px;
`;

const Container = styled.span`
  width: 100%;

  img {
    border-radius: 2px;
  }

  .Topics-label {
    margin: 5px 0;
  }

  .Topics-label-info {
    font-weight: normal;
    padding-right: 10px;
    color: ${({ theme }) => theme.colors.text.paragraph};
  }

  .Topics-label,
  .Stack-items-label {
    color: ${({ theme }) => theme.colors.text.subTitle};
  }

  .Button-turnback {
    padding-right: revert !important;
    margin-bottom: 15px;
    span {
      display: flex;
      flex-direction: column-reverse;
      flex-direction: row;
    }
  }

  .Project-topics {
    margin: 2%;
  }

  #Project-topics-stack {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
  }

  .Stack-items {
    margin-bottom: 10px;
    list-style-type: none;
    ul {
      white-space: nowrap;
    }
    & > ul {
      list-style-type: disc;
    }

    .Stack-item {
      ul {
        margin-left: 20px;
        font-size: 0.8rem;
        line-height: 1.2rem;
        opacity: 0.8;
      }
    }
  }

  .Project-topics-true {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text.subTitle};
  }

  & > ul > li {
    margin: 0% 5%;
  }
`;
