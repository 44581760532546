import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { bindActionCreators, Dispatch } from "redux";

import { AppTranslations } from "../../../assets/translations";
import { PageLanguage, ProjectDTO, DisplayType, RouteType, Workplace } from "../../../constants/types";
import { ApplicationState } from "../../../store";
import { navigate } from "../../../store/ducks/menu";
import { loadRequest, changeWorkplace } from "../../../store/ducks/projects/actions";
import ListUtils from "../../../Utils/ListUtils";
import List from "../../../Utils/ListUtils";
import ObjectUtils from "../../../Utils/ObjectUtils";
import StoreUtils from "../../../Utils/StoreUtils";
import StringUtils from "../../../Utils/StringUtils";

import { PROJECTS_BY_PAGE } from "./constants";
import {
  initialState,
  filterProjectsByTech as filterByTech,
  filterProjectsByCompany as filterByCompany,
  filterProjectsByYear as filterByYear,
  clickPagination,
  getFilters,
} from "./helpers";
import ProjectsPageView from "./ProjectsPageView";
import { SetStateType, StateType } from "./types";

let LABEL_ALL_OPTIONS = AppTranslations.EN.components.options.TEXT_SELECT_ALL_OPTIONS;

type StateProps = {
  displayType: DisplayType;
  pageLanguage: PageLanguage;
  projectsData: Array<ProjectDTO>;
  projectsError: boolean;
  projectsLoading: boolean;
  workplace: Workplace;
};

type OwnProps = {
  filters?: Array<string>;
};

type DispatchProps = {
  changeWorkplace(workplace: Workplace): void;
  loadRequest(pageLanguage: PageLanguage, workplace: Workplace): void;
  navigate: (route: RouteType) => void;
};

type Props = StateProps & OwnProps & DispatchProps;

const ProjectsPage: React.FC<Props> = (props) => {
  const [state, setState] = useState(initialState(props.pageLanguage, props.filters));

  LABEL_ALL_OPTIONS = AppTranslations[props.pageLanguage].components.options.TEXT_SELECT_ALL_OPTIONS;

  const { displayType, projectsError, projectsData, projectsLoading } = props;
  const projectStateIsEmpty = StoreUtils.StateIsEmpty({ data: projectsData, loading: projectsLoading, error: projectsError });
  const projectsAll = projectsData;
  const isFilterActive = isSomeFilterActive(state);
  const projects = orderProjectsBy(isFilterActive ? state.filteredProjects : projectsAll, state.selectedOderByOption, props.pageLanguage);

  const isFilterNotFoundProjects = isFilterActive && state.filteredProjects.length < 1 && projectsAll.length > 0;

  const totalProjectsToPagination = projects.length;
  const projectsPageView = projectStateIsEmpty ? [] : List.getElementsPerPage(state.page, projects, PROJECTS_BY_PAGE[props.workplace]);

  const isError = projectsError;
  const isLoading = projectsLoading || projectStateIsEmpty || state.isLoadingFilters;
  const hasProjectsAvailableToFilter = state.isLoadingFilters && projectsPageView.length > 0;
  const hasErrorAndSearchIsActivated = (isError || isFilterNotFoundProjects) && !!state.finishLoadingFilterByTech;
  const finishLoopingSearch = !!state.finishLoadingFilterByTech && !List.isNullOrEmpty(state.filteredProjects) && state.isActiveTechFilter;

  if (projectStateIsEmpty) props.loadRequest(props.pageLanguage, props.workplace);
  if (hasProjectsAvailableToFilter) filterProjects(projectsAll, isError, state, setState);
  if (hasErrorAndSearchIsActivated || finishLoopingSearch) clearLoopingSearch(state, setState);

  useEffect(() => {
    if (projectsAll.length > 0) updateSearchFilters(state, setState, projectsAll);
  }, [projectsAll]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSomeFilterActive(state) && projectsAll.length > 0) setState(buildNewStateToRestartSearch);

    // When the workplace change and there are no data for that selected workplace,
    // should reset state to initial state to maintain the filters
    if (ListUtils.isNullOrEmpty(props.projectsData)) {
      updateStateWithInitialStateIfTheyAreNotEquals(state, initialState(props.pageLanguage, props.filters), setState);
    }
  }, [props.workplace, props.pageLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProjectsPageView
      changeWorkplace={props.changeWorkplace}
      companyFilters={state.filters.company}
      displayType={displayType}
      forceFilterTech={state.forceFilterTech}
      handleClickPagination={(page: number) => clickPagination(page, ID_CSS_HEADER, setState)}
      handleFilterProjectsByCompany={(filter?: string) => handleFilterPrjByCompany(projectsAll, setState, filter)}
      handleSelectOrderBy={(option = "") => handleSelectedOrderBy(option, setState)}
      handleFilterProjectsByTech={(filter: string, func: () => void) => filterProjectsByTech(projectsAll, isError, setState, filter, func)}
      handleFilterProjectsByYear={(filter?: string) => handleFilterProjectsByYear(projectsAll, setState, filter)}
      idCssHeader={ID_CSS_HEADER}
      isError={isError}
      isFilterActive={isFilterActive}
      isLoading={isLoading}
      navigate={props.navigate}
      onClickTryAgain={() => props.loadRequest(props.pageLanguage, props.workplace)}
      page={state.page}
      pageLanguage={props.pageLanguage}
      projects={projectsPageView}
      projectsPerPage={PROJECTS_BY_PAGE[props.workplace]}
      selectedCompanyFilter={state.selectedCompanyFilter}
      selectOderByOption={state.selectedOderByOption}
      selectedTechFilters={state.selectedTechFilters}
      selectedTechFilterUserInput={state.selectedTechFilterUserInput}
      selectedYearFilter={state.selectedYearFilter}
      totalProjects={projectsAll.length}
      totalProjectsPagination={totalProjectsToPagination}
      workplace={props.workplace}
      yearFilters={state.filters.year}
    />
  );
};

const ID_CSS_HEADER = "Projects-header";

const isSomeFilterActive = (state: StateType) => state.isActiveCompanyFilter || state.isActiveTechFilter || state.isActiveYearFilter;

const mapStateToProps = (state: ApplicationState) => ({
  projectsLoading: state.projects.loading,
  projectsError: state.projects.error,
  projectsData: state.projects.data[state.pageLanguage][state.workplace],
  workplace: state.workplace,
  pageLanguage: state.pageLanguage,
  displayType: state.displaySize.type,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ loadRequest, navigate, changeWorkplace }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);

/**
 * Function as a wrapper to call MAIN FUNCTION to FILTER projects by COMPANY
 * @param {Array<ProjectDTO>} projects
 * @param {SetStateType} setState
 * @param {string} filter
 */
const handleFilterPrjByCompany = (projects: Array<ProjectDTO>, setState: SetStateType, filter?: string) => {
  if (filter) filterByCompany(projects, setState, filter, LABEL_ALL_OPTIONS);
};

/**
 * Function as a wrapper to call MAIN FUNCTION to FILTER projects by YEAR
 * @param {Array<ProjectDTO>} projects
 * @param {SetStateType} setState
 * @param {string} filter
 */
const handleFilterProjectsByYear = (projects: Array<ProjectDTO>, setState: SetStateType, filter?: string) => {
  if (filter) filterByYear(projects, setState, filter, LABEL_ALL_OPTIONS);
};

/**
 * Function to update state with option selected and reset to page 1
 * @param {string} option
 * @param {SetStateType} setState
 */
const handleSelectedOrderBy = (option: string, setState: SetStateType) => {
  setState((prev) => ({ ...prev, selectedOderByOption: option, page: 1 }));
};

/**
 * Function as a wrapper to call MAIN FUNCTION to FILTER projects by TECHNOLOGIES
 * @param {Array<ProjectDTO>} projects
 * @param {boolean} isError
 * @param {SetStateType} setState
 * @param {string} filter
 * @param {function} finishLoadingFunc
 * @param {function} clearSearchFunc
 */
const filterProjectsByTech = (
  projects: Array<ProjectDTO>,
  isError: boolean,
  setState: SetStateType,
  filter: string,
  finishLoadingFunc?: () => void,
  clearSearchFunc?: () => void
) => {
  if (projects.length > 0) filterByTech(projects, setState, filter, finishLoadingFunc, clearSearchFunc);
  else if (!isError) {
    setState((prev) => ({
      ...prev,
      finishLoadingFilterByTech: prev.finishLoadingFilterByTech || finishLoadingFunc,
      clearSearchFilterByTech: prev.clearSearchFilterByTech || clearSearchFunc,
    }));
  }
};

/**
 * Function as a wrapper to filter projects by filters selected in the STATE like TECH or COMPANY
 * @param {Array<ProjectDTO>} projects
 * @param {boolean} isError
 * @param {StateType} state
 * @param {SetStateType} setState
 */
const filterProjects = (projects: Array<ProjectDTO>, isError: boolean, state: StateType, setState: SetStateType) => {
  if (projects.length > 0 && state.isLoadingFilters) {
    if (ListUtils.isNotNullOrEmpty(state.selectedTechFilters)) {
      filterProjectsByTech(projects, isError, setState, state.selectedTechFilters.join(" "));
    }
    if (StringUtils.isNotNullOrEmpty(state.selectedCompanyFilter)) {
      handleFilterPrjByCompany(projects, setState, state.selectedCompanyFilter);
    }
    if (StringUtils.isNotNullOrEmpty(state.selectedYearFilter)) {
      handleFilterProjectsByYear(projects, setState, state.selectedYearFilter);
    }
  }
};

/**
 * Function to clear the state of the looping search
 * @param {StateType} oldState
 * @param {SetStateType} setState
 */
const clearLoopingSearch = (oldState: StateType, setState: SetStateType) => {
  setTimeout(() => {
    if (oldState.finishLoadingFilterByTech) oldState.finishLoadingFilterByTech();
  }, 300);

  setState((prev) => ({ ...prev, isLoadingFilters: false, finishLoadingFilterByTech: undefined }));
};

/**
 * Function to order projects by option selected
 * @param {Array<ProjectDTO>} projects
 * @param {string} option
 * @returns {Array<ProjectDTO>}
 */
const orderProjectsBy = (projects: Array<ProjectDTO>, option: string, pageLanguage: PageLanguage): Array<ProjectDTO> => {
  if (ListUtils.isNullOrEmpty(projects)) return projects;

  const options = AppTranslations[pageLanguage].components.projects.selectOrderbyButton.options;

  const sortByStartDateOn = (prj1: ProjectDTO, prj2: ProjectDTO, order: "asc" | "desc"): number => {
    let a = prj1;
    let b = prj2;

    if (order === "desc") {
      a = prj2;
      b = prj1;
    }

    if (!a.projectEnd || !b.projectEnd) {
      if (!a.projectStart || !b.projectStart) return 0;
      return b.projectStart.getTime() - a.projectStart.getTime();
    }

    return b.projectEnd.getTime() - a.projectEnd.getTime();
  };

  switch (option) {
    case options.RECENT:
      return ObjectUtils.clone(projects).sort((a, b) => sortByStartDateOn(a, b, "asc"));
    case options.OLDER:
      return ObjectUtils.clone(projects).sort((a, b) => sortByStartDateOn(a, b, "desc"));
    default:
      return projects;
  }
};

/**
 * Update search filters for each new search which all projects were updated
 * @param {StateType} oldState
 * @param {SetStateType} setState
 */
const updateSearchFilters = (state: StateType, setState: SetStateType, projects: Array<ProjectDTO>): void => {
  const newFilters = getFilters(LABEL_ALL_OPTIONS, projects);

  if (ObjectUtils.notEquals(state.filters, newFilters)) {
    setState((prev) => ({
      ...prev,
      filters: newFilters,
    }));
  }
};

/**
 * Function to update state for changes of workplaces when the workplace data is empty the state must be initial state
 * to maintain the firsts filters.
 * Steps: the initial state by url query > change workplace > maintain the initial filter
 *
 * @param {StateType} prevState: current or prev state.
 * @param {StateType} initialState: initial state to be updated
 * @param {SetStateType} setState
 */
const updateStateWithInitialStateIfTheyAreNotEquals = (prevState: StateType, initialState: StateType, setState: SetStateType): void => {
  if (ObjectUtils.notEquals(prevState, initialState)) setState(initialState);
};

const buildNewStateToRestartSearch = (prev: StateType): StateType => ({
  ...prev,
  filteredProjects: [],
  forceFilterTech: true,
  isLoadingFilters: true,
  isActiveYearFilter: false,
  isActiveCompanyFilter: false,
  isActiveTechFilter: false,
});
