export type Route = {
  id: string;
  name: string;
  path: string;
};

export const ROUTES = {
  ABOUT_ME: {
    id: '1',
    name: 'About Me',
    path: '/about-me',
  },
  BLOG: {
    id: '2',
    name: 'Blog',
    path: '/blog',
  },
  BLOG_POST: {
    id: '21',
    name: 'Blog',
    path: '/blog/:postId',
  },
  EDUCATION: {
    id: '3',
    name: 'Education',
    path: '/education',
  },
  EDUCATION_COLLEGES: {
    id: '4',
    name: 'Colleges',
    path: '/education/colleges',
  },
  EDUCATION_COURSES: {
    id: '5',
    name: 'Colleges',
    path: '/education/courses',
  },
  EDUCATION_LANGUAGES: {
    id: '6',
    name: 'languages',
    path: '/education/languages',
  },
  EDUCATION_RECOGNITIONS: {
    id: '7',
    name: 'languages',
    path: '/education/recognitions',
  },
  HOME: {
    id: '8',
    name: 'Home',
    path: '/',
  },
  WORK_EXPERIENCE: {
    id: '9',
    name: 'Work Experience',
    path: '/work-experience',
  },
  WORK_EXPERIENCE_JOBS: {
    id: '10',
    name: 'Jobs',
    path: '/work-experience/jobs',
  },
  WORK_EXPERIENCE_JOBS_JOB_DETAILS: {
    id: '11',
    name: 'Jobs',
    path: '/work-experience/jobs/:jobId',
  },
  WORK_EXPERIENCE_SKILLS: {
    id: '12',
    name: 'Skills',
    path: '/work-experience/skills',
  },
  WORK_EXPERIENCE_PROJECTS: {
    id: '13',
    name: 'Projects',
    path: '/work-experience/projects',
  },
  WORK_EXPERIENCE_PROJECTS_DETAILS: {
    id: '14',
    name: 'Project Details',
    path: '/work-experience/projects/:projectId',
  },
  WORK_EXPERIENCE_PROJECTS_FILTER: {
    id: '15',
    name: 'Projects',
    path: '/work-experience/projects?',
  },
};
