import React, { Fragment, useLayoutEffect } from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import { AppTranslations } from "../../assets/translations";
import { PageLanguage } from "../../constants/types";
import { ApplicationState } from "../../store";
import { TitlePageContent as Title } from "../PrintPageContent";

type StateProps = {
  pageLanguage: PageLanguage;
};

type OwnProps = {
  idCss?: string;
  children?: never;
  className?: string;
  onClickTryAgain?: () => void;
  position?: number;
  message?: string;
  style?: React.CSSProperties;
  onRendered?: () => void;
  title?: string;
  href?: string;
};

type Props = StateProps & OwnProps;

const Error: React.FC<Props> = ({
  idCss = "",
  className = "",
  message,
  onClickTryAgain,
  onRendered,
  position = 50,
  pageLanguage,
  style,
  title,
  href,
}) => {
  const { TEXT_INFO_ERRO, TEXT_TITLE_INFO_ERRO } = AppTranslations[pageLanguage].info;
  const { TEXT_TRY_AGAIN } = AppTranslations[pageLanguage].links;

  useLayoutEffect(() => {
    if (onRendered) onRendered();
  });

  return (
    <Container id={idCss} className={className} position={position} style={style}>
      <br />
      <Title>{title || TEXT_TITLE_INFO_ERRO}</Title>
      <br />
      <p>
        {(message && <Message text={message} />) || TEXT_INFO_ERRO}
        {href && (
          <a href={href} rel="noopener noreferrer" target="_blank" title={href}>
            LINK
          </a>
        )}
        {onClickTryAgain && (
          <Fragment>
            <br />
            <span className="Global-effect-ripple" onClick={onClickTryAgain}>
              {TEXT_TRY_AGAIN}
            </span>
            <br />
          </Fragment>
        )}
      </p>
      <br />
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  pageLanguage: state.pageLanguage,
});

export default connect(mapStateToProps)(Error);

const Message = (props: { text: string }) => {
  return (
    <Fragment>
      {props.text.split("\n").map((t) => (
        <>
          {t}
          <br />
        </>
      ))}
    </Fragment>
  );
};

const Container = styled.section<{ position: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 5%;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary.light};
    cursor: pointer;
    padding: 5px;

    :hover {
      text-decoration: underline;
    }
  }
`;
