import React, { Fragment } from 'react';

import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import MenuConteinerView from '@material-ui/lab/TreeView';

import { AppTranslations } from '../../../../assets/translations';
import { ROUTES } from '../../../../constants/routesConstants';
import { PageLanguage } from '../../../../constants/types';
import {
  AboutMeIcon,
  BlogsIcon,
  CollegesIcon,
  CoursesIcon,
  HomeIcon,
  JobsIcon,
  LanguagesIcon,
  ProjetsIcon,
  SkillsIcon,
  RecognitionsIcon,
} from '../../../Icons';

import { Container } from './styles';
import MenuNavItem from './TreeMenuItem';

type Props = {
  idCss?: string;
  expanded: Array<string>;
  pageLanguage: PageLanguage;
  selected: string | undefined;
  totalProjects: string;
  totalJobs: string;

  toHome: () => void;
  toAboutMe: () => void;
  toJobs: () => void;
  toPrjs: () => void;
  toSkill: () => void;
  toColleges: () => void;
  toCourses: () => void;
  toLanguages: () => void;
  toRecognitions: () => void;
  toBlogs: () => void;
};

const TreeMenuView: React.FC<Props> = (props) => {
  const { idCss = '', pageLanguage, totalJobs, totalProjects } = props;
  const {
    TEXT_HOME,
    TEXT_ABOUT_ME,
    TEXT_WORK_EXPERIENCE,
    TEXT_JOBS,
    TEXT_PROJECTS,
    TEXT_SKILLS,
    TEXT_EDUCATION,
    TEXT_COLLEGES,
    TEXT_COURSES,
    TEXT_LANGUAGES,
    TEXT_RECOGNITIONS,
    TEXT_BLOGS,
  } = AppTranslations[pageLanguage].menu;

  const {
    HOME,
    ABOUT_ME,
    WORK_EXPERIENCE: WORK_EXP,
    WORK_EXPERIENCE_JOBS: WORK_EXP_JOBS,
    WORK_EXPERIENCE_PROJECTS: WORK_EXP_PRJ,
    WORK_EXPERIENCE_SKILLS: WORK_EXP_SKI,
    EDUCATION,
    EDUCATION_COLLEGES,
    EDUCATION_COURSES,
    EDUCATION_LANGUAGES,
    EDUCATION_RECOGNITIONS: EDU_RECOGNI,
    BLOG,
  } = ROUTES;

  return (
    <Container id={idCss}>
      <MenuConteinerView
        defaultCollapseIcon={<KeyboardArrowDown />}
        defaultExpandIcon={<ChevronRight />}
        expanded={props.expanded}
        selected={props.selected}
      >
        <MenuNavItem nodeId={HOME.id} labelText={TEXT_HOME} onClick={props.toHome} labelIcon={HomeIcon} />
        <MenuNavItem nodeId={ABOUT_ME.id} labelText={TEXT_ABOUT_ME} onClick={props.toAboutMe} labelIcon={AboutMeIcon} />

        <MenuNavItem expanded nodeId={WORK_EXP.id} labelText={TEXT_WORK_EXPERIENCE} onClick={props.toJobs} labelIcon={Fragment}>
          <MenuNavItem nodeId={WORK_EXP_JOBS.id} labelText={TEXT_JOBS} labelInfo={totalJobs} onClick={props.toJobs} labelIcon={JobsIcon} />
          <MenuNavItem
            nodeId={WORK_EXP_PRJ.id}
            labelText={TEXT_PROJECTS}
            labelInfo={totalProjects}
            onClick={props.toPrjs}
            labelIcon={ProjetsIcon}
          />
          <MenuNavItem nodeId={WORK_EXP_SKI.id} labelText={TEXT_SKILLS} onClick={props.toSkill} labelIcon={SkillsIcon} />
        </MenuNavItem>

        <MenuNavItem expanded nodeId={EDUCATION.id} labelText={TEXT_EDUCATION} onClick={props.toLanguages} labelIcon={Fragment}>
          <MenuNavItem nodeId={EDUCATION_LANGUAGES.id} labelText={TEXT_LANGUAGES} onClick={props.toLanguages} labelIcon={LanguagesIcon} />
          <MenuNavItem nodeId={EDUCATION_COLLEGES.id} labelText={TEXT_COLLEGES} onClick={props.toColleges} labelIcon={CollegesIcon} />
          <MenuNavItem nodeId={EDUCATION_COURSES.id} labelText={TEXT_COURSES} onClick={props.toCourses} labelIcon={CoursesIcon} />
          <MenuNavItem nodeId={EDU_RECOGNI.id} labelText={TEXT_RECOGNITIONS} onClick={props.toRecognitions} labelIcon={RecognitionsIcon} />
        </MenuNavItem>

        <MenuNavItem nodeId={BLOG.id} labelText={TEXT_BLOGS} onClick={props.toBlogs} labelIcon={BlogsIcon} />
      </MenuConteinerView>
    </Container>
  );
};

export default TreeMenuView;
