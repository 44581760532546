import React from 'react';

import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

const LoadingDashboard: React.FC = (props) => {
  return (
    <Container>
      <Skeleton className="Dashboard-loading-title" animation="wave" variant="rect" height="20%" />
      <Skeleton className="Dashboard-loading-content" animation="wave" variant="rect" height="80%" />
    </Container>
  );
};

export default LoadingDashboard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 35vh;
  max-height: 500px;
  min-height: 360px;
  margin: 0% 10%;

  .Dashboard-loading-title {
    margin-bottom: 2%;
  }
`;
