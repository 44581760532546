import React from "react";

import styled from "styled-components";

import { PagesTranslations } from "../../../../assets/translations";
import { Divider, Ranking, SkillsDashboard, ToggleWorkspace } from "../../../../components";
import { ROUTES } from "../../../../constants/routesConstants";
import { PageLanguage, RouteType } from "../../../../constants/types";
import HeaderTitle from "../HeaderTitle";

type Props = {
  idCss: string;
  navigate: (route: RouteType) => void;
  pageLanguage: PageLanguage;
};

const Skills: React.FC<Props> = (props) => {
  const { TEXT_TITLE } = PagesTranslations[props.pageLanguage].HOME.skills;
  const TEXT_BUTTON = PagesTranslations[props.pageLanguage].PROJECTS.TEXT_TITLE;

  const navigateSkillsPage = () => props.navigate(ROUTES.WORK_EXPERIENCE_PROJECTS);

  return (
    <Section id={props.idCss} className="Global-paper">
      <HeaderTitle className="Section-Skills" title={TEXT_TITLE} textButton={TEXT_BUTTON} onClick={navigateSkillsPage} />

      <Divider className="Section-Skills-divider" />

      <ToggleWorkspace idCss="Section-Skills-toggle" />

      <SkillsDashboard navigate={props.navigate} />
      <Divider className="Section-Skills-divider" />

      <Ranking className="Section-Skills Section-Ranking" homepage />
    </Section>
  );
};

export default Skills;

const Section = styled.section`
  overflow-x: hidden;

  #Section-Skills-toggle {
    margin: 30px 0;

    @media (max-height: 700px) {
      margin-top: 25px;
      margin-bottom: 10px;
    }
  }
`;
