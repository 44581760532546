import React from 'react';
import { useLocation } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { ROUTES } from '../../constants/routesConstants';

const cssToCommonPage = css`
  @media (max-height: 840px) and (min-width: 1025px) {
    padding-left: 25%;

    @media (min-width: 1800px) {
      & {
        padding-left: 20%;
      }
    }

    @media (min-width: 2000px) {
      & {
        padding-left: 15%;
      }
    }

    @media (min-width: 2200px) {
      & {
        padding-left: 5%;
      }
    }
  }
`;

const Container = styled.footer<{ isHomePage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: ${({ theme }) => (theme.title === 'light' ? theme.colors.background.border : theme.colors.disable)};
  background: ${({ theme }) => (theme.title === 'light' ? theme.colors.background.backgroundBlack : theme.colors.background.secondary)};
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;

  height: 8vh;
  min-height: 75px;
  padding: 0 5vw;
  font-size: 14pt;

  ${({ isHomePage }) => (isHomePage ? '' : cssToCommonPage)}

  @media (max-width: 500px) {
    font-size: 0.8rem;
    height: 130px;
    align-items: start;
    padding-top: 25px;

    background: ${({ theme, isHomePage }) =>
      theme.title === 'light' ? theme.colors.background.backgroundBlack : theme.colors.background.secondary};
  }
`;

const Footer: React.FC = (props) => {
  let location = useLocation(); // eslint-disable-line prefer-const
  const isHomePage = location.pathname === ROUTES.HOME.path;
  return (
    <Container id="Footer" isHomePage={isHomePage}>
      © Copyright 2019-{`${new Date().getFullYear()}`} Matheus Icaro
    </Container>
  );
};

export default Footer;
