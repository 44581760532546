import { AnyAction } from "redux";

import { RankingSkills, Workplace } from "../../../constants/types";

/*
 * Action Types
 */
export enum Types {
  LOAD_REQUEST = "@rankingTecnologies/LOAD_REQUEST",
  LOAD_SUCCESS = "@rankingTecnologies/LOAD_SUCCESS",
  LOAD_FAILURE = "@rankingTecnologies/LOAD_FAILURE",
}

export type State = {
  readonly data: {
    [key in Workplace]: RankingSkills | null;
  };
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  workspace: Workplace;
  ranking: RankingSkills;
};

export interface Action extends AnyAction {
  payload: Payload;
}
