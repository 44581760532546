import React, { Fragment } from 'react';

import { PagesTranslations } from '../../../assets/translations';
import { Dialog, Error, LoadingSpin, PrintPageContent } from '../../../components';
import { Blog, PageContent, PageLanguage, Post } from '../../../constants/types';

import PostCard, { PostCardData, PostCardType } from './PostCard';

import { PostState } from '.';

type Props = {
  pageLanguage: PageLanguage;
  blogData: Array<Blog>;
  handleClickInPost: (post: Post) => void;
  closeDialog: () => void;
  isDialogOpen: boolean;
  post: PostState;
};

const BlogPage: React.FC<Props> = ({ pageLanguage, blogData, handleClickInPost, closeDialog, isDialogOpen, post }) => {
  const BLOG = PagesTranslations[pageLanguage].BLOG as { [string: string]: string };

  const PostContent = ({ post, pageContent }: { post: Post; pageContent: Array<PageContent> }) => (
    <Fragment>
      <PostCardType postType={BLOG[post.type]} />
      <PostCardData date={post.date} pageLanguage={pageLanguage} />
      <br />
      <article>
        <PrintPageContent pageContent={pageContent} />
      </article>
    </Fragment>
  );

  const PostView = ({ post }: { post: PostState }) => {
    if (post.error) return <Error />;
    else if (post.loading) return <LoadingSpin style={{ left: '45%', margin: '10% 0' }} />;
    return post.data && post.data.content ? <PostContent post={post.data} pageContent={post.data.content} /> : <Error />;
  };

  return (
    <Fragment>
      <Dialog open={isDialogOpen} onClickClose={closeDialog} pageLanguage={pageLanguage} title={post.data?.title}>
        <PostView post={post} />
      </Dialog>

      {blogData.map((values) => (
        <Fragment key={values.year}>
          <h3 style={{ alignSelf: 'start ', margin: '2%' }}> [ {values.year} ]</h3>

          {values.posts.map((post) => (
            <PostCard
              key={post.id}
              pageLanguage={pageLanguage}
              post={post}
              postType={BLOG[post.type]}
              onClick={() => handleClickInPost(post)}
            />
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default BlogPage;
