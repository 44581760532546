import React, { useEffect, useState } from 'react';

import { useTheme } from '../../styles/provider';
import { ThemeLightIcon, ThemeDarkIcon } from '../Icons';

import Button from './Button';

type Props = {
  id?: string;
  children?: never;
};

const ThemeButton: React.FC<Props> = ({ id = '' }) => {
  const [animate, setAnimate] = useState(false);
  const { toggleTheme, theme, themeMounted } = useTheme();

  const handleToggleTheme = () => {
    setAnimate(true);
    setTimeout(() => toggleTheme(), 100);
  };

  useEffect(() => {
    if (animate && themeMounted) setAnimate(false);
  }, [themeMounted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Button
      id={id}
      className={`Global-default-colors Global-animate-${animate}`}
      disabled={animate}
      icon={theme === 'light' ? ThemeLightIcon : ThemeDarkIcon}
      onClick={handleToggleTheme}
    />
  );
};

export default ThemeButton;
