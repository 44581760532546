import React from 'react';

import styled from 'styled-components';

type Props = {
  children?: never;
  className?: string;
  id?: string;
  position?: 'vertical' | 'horizontal';
  style?: React.CSSProperties;
};

const Hr = styled.hr<Props>`
  border: none;
  ${(props) => (props.position === 'horizontal' ? 'height' : 'width')}: 1px;

  margin: 0;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.background.border};
`;

const Divider: React.FC<Props> = ({ className = '', id = '', position = 'horizontal', style }) => {
  return <Hr id={id} className={`Divider-component ${className}`} position={position} style={style} />;
};

export default Divider;
