import { DisplayType } from '../constants/types';

export const getDeviceType = (width: number, height: number): DisplayType => {
  if (width <= 500) {
    return 'sm';
  } else if (width <= 600 || (width <= 1024 && height <= 640)) {
    return 'smd';
  } else if (width <= 768) {
    return 'md';
  } else if (width <= 1024) {
    return 'mdl';
  } else if (width <= 1200) {
    return 'lg';
  } else {
    return 'xlg';
  }
};

export const isMobileDevice = (size: DisplayType) => size === 'mdl' || size === 'md' || size === 'smd' || size === 'sm';
export const isMobileDeviceMD = (size: DisplayType) => size === 'md' || size === 'smd' || size === 'sm';
export const isMobilePhone = (size: DisplayType) => size === 'smd' || size === 'sm';
export const isMobilePhoneSM = (size: DisplayType) => size === 'sm';

const DisplayUtils = {
  getDeviceType,
  isMobileDevice,
  isMobileDeviceMD,
  isMobilePhone,
  isMobilePhoneSM,
};

export default DisplayUtils;
