import { combineReducers } from 'redux';

import BlogReducer from './blog/blogReducer';
import CollegesReducer from './colleges/collegesReducer';
import DisplaySize from './displaySize';
import HighlightsReducer from './highlights/highlightsReducer';
import JobsReducer from './jobs/jobsReducer';
import LanguagesReducer from './languages/languagesReducer';
import MenuReducer from './menu/index';
import PageLanguageReducer from './pageLanguage';
import ProfileReducer from './profile/profileReducer';
import ProjectsReducer from './projects/projectsReducer';
import RankingSkillsReducer from './ranking/rankingSkillsReducer';
import SkillsReducer from './skills/skillsReducer';
import WorkplaceReducer from './workplace';

export const rootReducer = combineReducers({
  blog: BlogReducer,
  colleges: CollegesReducer,
  displaySize: DisplaySize,
  highlights: HighlightsReducer,
  jobs: JobsReducer,
  languages: LanguagesReducer,
  menu: MenuReducer,
  pageLanguage: PageLanguageReducer,
  profile: ProfileReducer,
  projects: ProjectsReducer,
  RankingSkills: RankingSkillsReducer,
  skills: SkillsReducer,
  workplace: WorkplaceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
