import React, { Fragment, useState } from 'react';

import { AppTranslations } from '../../assets/translations';
import { PageLanguage, PageContent } from '../../constants/types';
import LinkButton from '../Buttons/LinkButton';
import { QuestionIcon } from '../Icons';
import PrintPageContent from '../PrintPageContent';

import Dialog from '.';

type Props = {
  pageLanguage: PageLanguage;
  title: string;
  content: Array<PageContent>;
};

const DialogHowItsWork: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const { TEXT_HOW_WORKS } = AppTranslations[props.pageLanguage].links;

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <Fragment>
      <LinkButton
        className="Button-how-its-work"
        handleClick={handleClick}
        styleText
        icon={<QuestionIcon />}
        position="end"
        text={TEXT_HOW_WORKS}
      />
      <Dialog open={open} onClickClose={handleClick} title={props.title} pageLanguage={props.pageLanguage}>
        <PrintPageContent pageContent={props.content} />
      </Dialog>
    </Fragment>
  );
};

export default DialogHowItsWork;
