import React, { Fragment } from 'react';

import { AppTranslations } from '../../assets/translations';
import { Skill, PageLanguage } from '../../constants/types';
import DialogHowItsWork from '../Dialog/HowItsWorkDialog';

import Header from './components/Header';
import PanelSkill from './components/PanelSkill';
import { ContainerDashboard } from './styles';

import { TransitionEffect } from '..';

type Props = {
  currentOption: number;
  handleMenuOption: (newValue: number) => void;
  handleDialogHowItsWork: () => void;
  openHowItsWork: boolean;
  onClickElementPanel: (name: string) => void;
  pageLanguage: PageLanguage;
  skills: Array<Skill>;
};

const DashboardView: React.FC<Props> = (props) => {
  const { DIALOG_HOW_ITS_WORKS } = AppTranslations[props.pageLanguage].components.skillDashboard;
  const { currentOption, handleMenuOption, onClickElementPanel, skills } = props;

  return (
    <TransitionEffect>
      <ContainerDashboard id="Skills-container">
        <Header
          idCss="Skills-dashboard-header"
          pageLanguage={props.pageLanguage}
          currentOption={currentOption}
          onSelectOption={handleMenuOption}
          skills={skills}
        />

        <DialogHowItsWork
          title={DIALOG_HOW_ITS_WORKS.TEXT_TITLE}
          content={DIALOG_HOW_ITS_WORKS.content}
          pageLanguage={props.pageLanguage}
        />

        <section id="Skills-dashboard">
          {skills.map((skill, i) => (
            <Fragment key={skill.name}>
              {currentOption === i && (
                <PanelSkill
                  idCss={`${skill.name}-${skill.projects}${currentOption}`}
                  key={skill.name}
                  skill={skill}
                  navigate={onClickElementPanel}
                  pageLanguage={props.pageLanguage}
                />
              )}
            </Fragment>
          ))}
        </section>
      </ContainerDashboard>
    </TransitionEffect>
  );
};

export default DashboardView;
