import React from 'react';
import { connect } from 'react-redux';

import { Dispatch, bindActionCreators } from 'redux';

import { ROUTES, Route } from '../../../constants/routesConstants';
import { PageLanguage, DisplayType } from '../../../constants/types';
import { ApplicationState } from '../../../store';
import { returnPreviousRoute } from '../../../store/ducks/menu';
import { setTimeoutOnClick } from '../../../Utils';
import DisplayUtils from '../../../Utils/DisplayUtils';

import ButtonsMenu from './ButtonsMenu';
import TreeMenu from './TreeMenu';

type StateToProps = {
  displayType: DisplayType;
  pageLanguage: PageLanguage;
  menuId: string;
  hasHistory: boolean;
};

type DispatchProps = {
  returnPreviousRoute: () => void;
};

type OwnProps = {
  idCss?: string;
  routeId?: string;
  onClickMenu?: () => void; // When onClick on menu return callback that clicked
  navigate: (route: Route) => void;
};

type Props = OwnProps & StateToProps & DispatchProps;

const NavegationBar: React.FC<Props> = (props) => {
  const { idCss = '', displayType, menuId, routeId, pageLanguage } = props;

  const isMobileDevice = DisplayUtils.isMobileDevice(displayType);
  const isMobilePhoneSM = DisplayUtils.isMobilePhoneSM(displayType);

  const navigate = (route: Route) => {
    props.navigate(route);
  };

  const middleware = (func: () => void) => {
    if (isMobileDevice && props.onClickMenu) setTimeoutOnClick(props.onClickMenu) && setTimeoutOnClick(() => func(), 1000);
    else func();
  };

  const turnBack = () => middleware(() => props.returnPreviousRoute());
  const toHome = () => middleware(() => navigate(ROUTES.HOME));
  const toAboutMe = () => middleware(() => navigate(ROUTES.ABOUT_ME));
  const toJobs = () => middleware(() => navigate(ROUTES.WORK_EXPERIENCE_JOBS));
  const toPrjs = () => middleware(() => navigate(ROUTES.WORK_EXPERIENCE_PROJECTS));
  const toSkill = () => middleware(() => navigate(ROUTES.WORK_EXPERIENCE_SKILLS));
  const toColleges = () => middleware(() => navigate(ROUTES.EDUCATION_COLLEGES));
  const toCourses = () => middleware(() => navigate(ROUTES.EDUCATION_COURSES));
  const toLanguages = () => middleware(() => navigate(ROUTES.EDUCATION_LANGUAGES));
  const toRecognitions = () => middleware(() => navigate(ROUTES.EDUCATION_RECOGNITIONS));
  const toBlogs = () => middleware(() => navigate(ROUTES.BLOG));

  if (isMobileDevice)
    return (
      <ButtonsMenu
        idCss={idCss}
        isMobilePhoneSM={isMobilePhoneSM}
        selected={routeId || menuId || ROUTES.HOME.id}
        pageLanguage={pageLanguage}
        toHome={toHome}
        toAboutMe={toAboutMe}
        toJobs={toJobs}
        toPrjs={toPrjs}
        toSkill={toSkill}
        toColleges={toColleges}
        toCourses={toCourses}
        toBlogs={toBlogs}
        toLanguages={toLanguages}
        toRecognitions={toRecognitions}
        turnBack={turnBack}
        turnBackDisabledButton={!props.hasHistory}
      />
    );

  return (
    <TreeMenu
      idCss={idCss}
      menuId={menuId}
      routeId={routeId}
      pageLanguage={pageLanguage}
      toHome={toHome}
      toAboutMe={toAboutMe}
      toJobs={toJobs}
      toPrjs={toPrjs}
      toSkill={toSkill}
      toColleges={toColleges}
      toCourses={toCourses}
      toLanguages={toLanguages}
      toRecognitions={toRecognitions}
      toBlogs={toBlogs}
    />
  );
};

const NavegationBarMemo = React.memo(NavegationBar);

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type,
  pageLanguage: state.pageLanguage,
  menuId: state.menu.route.id,
  hasHistory: state.menu.hasHistory,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ returnPreviousRoute }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavegationBarMemo);
