import React, { useLayoutEffect } from 'react';

import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

type Props = {
  idCss: string;
  className?: string;
  onRendered?: () => void;
};

export const LoadingSideProfile: React.FC<Props> = (props) => {
  useLayoutEffect(() => {
    if (props.onRendered) props.onRendered();
  });

  return (
    <ContainerSideProfile id={props.idCss} className={props.className}>
      <Skeleton id="Skeleton-profile-side-circle" animation="wave" variant="circle" />
      {['', '', '', ''].map((e, i) => (
        <Skeleton key={i} className="Skeleton-profile-side-lines" animation="wave" variant="text" height="5vh" width="100%" />
      ))}
    </ContainerSideProfile>
  );
};

export const LoadingProfile: React.FC<Props> = (props) => {
  useLayoutEffect(() => {
    if (props.onRendered) props.onRendered();
  });
  return (
    <ContainerProfile id={props.idCss} className={props.className}>
      <Skeleton id="Skeleton-profile-line-circle" animation="wave" variant="circle" />

      <span id="Profile-line-container">
        <Skeleton animation="wave" variant="text" height="29%" />
        <span id="Profile-line-content">
          <Skeleton animation="wave" variant="rect" height="100%" width="30%" />
          <Skeleton animation="wave" variant="rect" height="100%" width="65%" />
        </span>
      </span>
    </ContainerProfile>
  );
};

const ContainerSideProfile = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-width: 200px;
  min-height: 350px;

  margin-bottom: 10%;

  #Skeleton-profile-side-circle {
    width: 120px;
    height: 120px;
  }
  .Skeleton-profile-side-lines {
    min-height: 50px;
  }

  @media (max-height: 840px) {
    @media (max-width: 1600px) {
      & {
        padding: 1% 15%;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 1% 10%;
    height: 100% !important;
  }
  @media (max-width: 500px) {
    #Skeleton-profile-side-circle {
      width: 110px;
      height: 110px !important;
    }
    padding: 1%;
  }
`;

const ContainerProfile = styled.section`
  height: 250px;
  display: flex;

  #Profile-line-container {
    width: 100%;
    height: 100%;
    margin-left: 2%;
  }

  #Profile-line-content {
    display: flex;
    justify-content: space-between;
    height: 70%;
    margin-top: 1%;
  }

  #Skeleton-profile-line-circle {
    display: none;
  }

  @media (max-width: 940px) {
    align-items: center;
    height: 340px;

    #Skeleton-profile-line-circle {
      display: inherit;
      width: 120px;
      height: 150px;
    }
  }

  @media (max-width: 600px) {
    height: 480px;

    #Profile-line-content {
      flex-direction: column;
      span {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }
`;
