import React from 'react';

import styled from 'styled-components';

import { AppTranslations, PagesTranslations } from '../../../../assets/translations';
import { Divider } from '../../../../components';
import { LinkButton as ButtonText } from '../../../../components';
import Highlights from '../../../../components/Highlights';
import LanguageContainer from '../../../../components/Languages/LanguageContainer';
import { ROUTES } from '../../../../constants/routesConstants';
import { PageLanguage, RouteType } from '../../../../constants/types';
import { SectionByCollunm } from '../../styles';
import HeaderTitle from '../HeaderTitle';

import LanguagesView from './LanguagesView';

type Props = {
  idCss: string;
  pageLanguage: PageLanguage;
  navigate: (route: RouteType) => void;
};

const HighlightsAndLanguages: React.FC<Props> = (props) => {
  const { TEXT_TITLE_HIGHLIGHTS, TEXT_TITLE_LANGUAGES } = PagesTranslations[props.pageLanguage].HOME.highlightsAndLanguages;
  const { TEXT_MORE_DETAILS } = AppTranslations[props.pageLanguage].links;

  return (
    <Section id={props.idCss} className="Main-gaps">
      <section id="Section-highlights" className="Global-paper">
        <HeaderTitle noButton title={TEXT_TITLE_HIGHLIGHTS} />
        <Divider />
        <Highlights isHomePage idCss="Section-highlights" className="Highlights-text" />
        <Divider />
        <ButtonText handleClick={() => props.navigate(ROUTES.EDUCATION_RECOGNITIONS)} position="center" text={TEXT_MORE_DETAILS} />
      </section>

      <section id="Section-languages" className="Global-paper">
        <HeaderTitle noButton title={TEXT_TITLE_LANGUAGES} />
        <Divider />
        <LanguageContainer classNameForComponentView="Languages-itens" ComponentView={LanguagesView} />
      </section>
    </Section>
  );
};

export default HighlightsAndLanguages;

const Section = styled.section`
  ${SectionByCollunm}

  #Section-highlights {
    .Highlights-text {
      color: ${({ theme }) => theme.colors.text.paragraph};
    }

    li {
      color: ${({ theme }) => theme.colors.disable};
    }

    .Global-Button-center {
      display: block;
    }
  }
  .Languages-itens {
    margin-left: 5%;
    li {
      display: flex;
    }

    .Less-focus {
      color: ${({ theme }) => theme.colors.text.subTitle};
    }
  }
`;
