import { createHmac } from 'crypto';
import { sign, decode } from 'jsonwebtoken';
import { DateTime } from 'luxon';

import environments from '../environments';

type Token = {
  scope: string;
  createdIn: number;
  origin: string;
};

const Auth = () => {
  const token = getToken();

  if (!token || tokenIsExpired(token.createdIn)) {
    return createToken();
  }

  return token;
};

const getToken = () => {
  try {
    const token = localStorage.getItem('auth');
    return token ? (decode(token) as Token) : null;
  } catch (error) {
    return null;
  }
};

const createToken = (): Token => {
  const { millisecond } = DateTime.local().setZone(environments.region);
  const identity = {
    scope: environments.scope,
  };

  const newToken = {
    origin: environments.region,
    createdIn: millisecond,
    scope: createHmac(environments.apiCrypto, environments.secret)
      .update(JSON.stringify(identity))
      .digest('hex'),
  };

  localStorage.setItem('auth', sign(newToken, environments.storageCrypto));
  return newToken;
};

const tokenIsExpired = (createdIn: number) => {
  try {
    const dateNow = DateTime.local().setZone(environments.region);
    const tokenTime = DateTime.fromMillis(createdIn).setZone(environments.region);
    return dateNow.hour !== tokenTime.hour;
  } catch (err) {
    return true;
  }
};

export default {
  getToken: Auth,
};
