import React from 'react';

import Drawer from '@material-ui/core/Drawer';

import { Button as MenuButton, TransitionEffect } from '../..';
import { RouteType } from '../../../constants/types';
import { MenuIcon } from '../../Icons';

import ContentDrawer from './components/Content';
import FooterDrawer from './components/Footer';
import ToolbarDrawer from './components/Toolbar';
import { Container, HiddenBackground } from './styles';

type Props = {
  idCss?: string;
  closeDrawerMenu: () => void;
  openDrawerMenu: () => void;
  isMobileDevice: boolean;
  isMobilePhoneSM: boolean;
  isDrawerOpen: boolean;
  navigate: (route: RouteType) => void;
};

const DrawerMenuView: React.FC<Props> = ({
  closeDrawerMenu,
  openDrawerMenu,
  idCss = '',
  isMobileDevice,
  isMobilePhoneSM,
  isDrawerOpen,
  navigate,
}) => {
  return (
    <Container id={idCss} openDrawer={isDrawerOpen}>
      {isMobilePhoneSM && <MenuButton onClick={openDrawerMenu} icon={MenuIcon} />}

      <TransitionEffect start={isDrawerOpen && isMobileDevice} timeout={1200}>
        <HiddenBackground id="Drawer-menu-open-background" onClick={isMobilePhoneSM ? undefined : closeDrawerMenu} />
      </TransitionEffect>

      <Drawer id="Commonpage-aside-drawer" variant="permanent">
        {isMobileDevice && <ToolbarDrawer openDrawerMenu={openDrawerMenu} closeDrawerMenu={closeDrawerMenu} isDrawerOpen={isDrawerOpen} />}

        <ContentDrawer
          isMenuOpen={isDrawerOpen}
          isMobileDevice={isMobileDevice}
          isMobilePhoneSM={isMobilePhoneSM}
          closeDrawerMenu={closeDrawerMenu}
          navigate={navigate}
        />

        {isMobileDevice && <FooterDrawer isDrawerOpen={isDrawerOpen} onClickButton={openDrawerMenu} />}
      </Drawer>
    </Container>
  );
};

export default DrawerMenuView;
