import React from 'react';

import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

export const LoadingListRow: React.FC = () => {
  return (
    <Container>
      <Skeleton animation="wave" variant="text" height="30%" />
      <Skeleton animation="wave" variant="text" height="30%" />
      <Skeleton animation="wave" variant="text" height="30%" />
    </Container>
  );
};

export default LoadingListRow;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 25vh;
  width: 100%;
  justify-content: space-between;
`;
