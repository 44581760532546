import { css } from 'styled-components';

export const TransitionEffectOpen = css`
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 25%;

  @media (max-width: 1024px) {
    width: 75%;
  }
  @media (max-width: 790px) {
    width: 80%;
  }
  @media (max-width: 620px) {
    width: 100%;
  }
`;

export const TransitionEffectClose = css`
  width: 8vw;
  overflow-x: hidden;
  transition: width 295ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  @media (max-width: 940px) {
    width: 8.5vw;
  }

  @media (max-width: 680px) {
    width: 10.6vw;
  }

  @media (max-width: 500px) {
    width: 0%;
    border: 0;
  }
`;
