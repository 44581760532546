import { RankingSkills } from "../../constants/types";

import { PageLanguage } from "./../../constants/types";

export enum StateTypeOptionEnum {
  STARS = "STARS",
  PROJECTS = "PROJECTS",
  RECENTLY = "RECENTLY",
}

export type OptionStateType = {
  key: StateTypeOptionEnum;
  label: {
    [key in PageLanguage]?: string;
  };
};

export type StateType = {
  options: {
    selected: OptionStateType;
    values: Array<OptionStateType>;
  };
  ranking: RankingSkills | null;
  rankingOrderByDefault: RankingSkills | null;
};

export type OrderRankingClassificationByType = (
  type: "stars" | "projects",
  optionSelected: StateTypeOptionEnum,
  ranking: RankingSkills,
  setState: React.Dispatch<React.SetStateAction<StateType>>
) => void;
