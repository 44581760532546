import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';

import LogoBackground from '../../../assets/images/logo_background.png';
import { PagesTranslations } from '../../../assets/translations';
import { DisplayType, PageLanguage } from '../../../constants/types';
import { ApplicationState } from '../../../store';
import DisplayUtils from '../../../Utils/DisplayUtils';

type StateProps = {
  displayType: DisplayType;
};

type Props = {
  className?: string;
  pageLanguage: PageLanguage;
};

const Banner: React.FC<Props & StateProps> = ({ className = '', pageLanguage, displayType }) => {
  const device = DisplayUtils.isMobileDevice(displayType) ? 'mobileDevice' : 'desktop';
  const { TEXT_VAR, TEXT_FUNCTION } = PagesTranslations[pageLanguage].HOME.banner;
  const { TEXT_FIRST_ATRIBUTE, TEXT_FIRST_ATRIBUTE_VALUE, TEXT_SECOND_ATRIBUTE, TEXT_SECOND_ATRIBUTE_VALUE } = PagesTranslations[
    pageLanguage
  ].HOME.banner[device];

  const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

  return (
    <Container className={className} isSafari={isSafari}>
      <p>
        <Const>const </Const>
        <Var>{TEXT_VAR}</Var> = {'{'}
      </p>
      <p style={{ paddingLeft: '30px' }}>
        {TEXT_FIRST_ATRIBUTE}: <String>"{TEXT_FIRST_ATRIBUTE_VALUE}"</String>,
      </p>
      <p style={{ paddingLeft: '30px' }}>
        {TEXT_SECOND_ATRIBUTE}: <String>"{TEXT_SECOND_ATRIBUTE_VALUE}"</String>
      </p>
      <p>{'}'};</p>
      <p>
        <Const>this</Const>.<Func>{TEXT_FUNCTION}</Func>(<Var>{TEXT_VAR}</Var>);
      </p>
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  displayType: state.displaySize.type,
});

export default connect(mapStateToProps)(Banner);

const Const = styled.span`
  color: #2c7ab3;
`;

const Var = styled.span`
  color: #9cdcfe;
`;

const String = styled.span`
  color: #ce9178;
`;

const Func = styled.span`
  color: #dcdcaa;
`;

const Container = styled.section<{ isSafari: boolean }>`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  padding: 40px 5vw !important;
  padding-top: 150px !important;
  border: none !important;
  background-color: #2f2f2f !important;

  background-image: url(${LogoBackground}) !important;
  background-repeat: no-repeat !important;
  background-position: 90% 80% !important;
  background-size: 400px !important;

  color: rgb(255, 255, 255, 0.9);

  & p {
    border-right: ${({ isSafari }) => (isSafari ? 'none' : '0.15em solid')};
    font-family: 'Courier';
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    width: fit-content;

    @media (max-width: 380px) {
      overflow-x: scroll;
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-color: transparent;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
  @media (max-width: 1600px) {
    background-position: 98% 80% !important;
    background-size: 300px !important;
  }
  @media (max-width: 1400px) {
    background-position: 98% 100% !important;
    background-size: 200px !important;

    padding-left: 3vw !important;
    padding-right: 3vw !important;
    & p {
      font-size: 18px;
    }
  }
  @media (max-width: 1024px) {
    background-size: revert !important;
    background-position: 95% 100% !important;
  }

  @media (max-width: 768px) {
    background-size: 280px !important;
  }

  @media (max-width: 600px) {
    background-image: none !important;
  }

  & p {
    width: 100%;
    max-width: fit-content;
    opacity: ${({ isSafari }) => (isSafari ? 1 : 0)};

    -webkit-animation: type 2s steps(40, end), blink 0.2s step-end infinite alternate;
    animation: type 2s steps(40, end), blink 0.2s step-end infinite alternate;

    -webkit-animation-delay: 2s;
    animation-delay: 2s;

    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  & p:nth-child(2) {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }

  & p:nth-child(3) {
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
  }

  & p:nth-child(4) {
    -webkit-animation-delay: 8s;
    animation-delay: 8s;
  }

  & p:nth-child(5) {
    opacity: ${({ isSafari }) => (isSafari ? 1 : 0)};

    -webkit-animation: typefinal 2s steps(40, end), blink 0.2s step-end infinite alternate;
    animation: typefinal 2s steps(40, end), blink 0.2s step-end infinite alternate;

    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    animation-delay: 10s;
    -webkit-animation-delay: 10s;
  }

  @keyframes type {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: 0.15em solid;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }

  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: 0.15em solid;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }

  @keyframes typefinal {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes typefinal {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }

  @keyframes type {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: 0.15em solid;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }

  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: 0.15em solid;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }

  @keyframes typefinal {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes typefinal {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }
`;
