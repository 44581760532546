import { AnyAction } from "redux";
import { put } from "redux-saga/effects";

import { PageLanguage, ProjectDTO, Workplace } from "../../../constants/types";
import Api from "../../../services/api";

import { loadSuccess, loadFailure } from "./actions";

export interface Payload extends AnyAction {
  payload: {
    pageLanguage: PageLanguage;
    workplace: Workplace;
  };
}

type ProjectReqDTO = {
  cardImageLink?: string;
  company: string;
  id: string;
  introduction: string;
  origin: "COMPANY" | "GITHUB";
  projectEnd: string;
  projectStart: string;
  refLink?: string;
  tags: Array<string>;
  title: string;
  url?: string;
  weight?: number;
};

type Response = null | {
  data: Array<ProjectReqDTO>;
};

export function* loadProjects(action: Payload) {
  try {
    const { pageLanguage, workplace } = action.payload;
    let response: Response = null;

    switch (workplace) {
      case "both":
        response = yield Api.getProjectsAll(pageLanguage);
        break;

      case "github":
        response = yield Api.getProjectOfGithub(pageLanguage);
        break;

      case "jobs":
        response = yield Api.getProjectsOfJobs(pageLanguage);
        break;

      default:
        break;
    }

    if (!response || response.data.length < 1) throw new Error("Not found - empty");

    const projectsDTO: Array<ProjectDTO> = response.data.map((value) => ({
      ...value,
      projectEnd: new Date(value.projectEnd),
      projectStart: new Date(value.projectStart),
      refLink: value.refLink || value.url,
    }));

    yield put(loadSuccess({ workplace, pageLanguage, projects: projectsDTO }));
  } catch (err) {
    yield put(loadFailure());
  }
}
