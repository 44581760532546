import { createMuiTheme } from '@material-ui/core';

import dark from './dark';
import light from './light';

export const FONT_FAMILY = 'Didact Gothic, Roboto, Helvetica, Arial, sans-serif';

export const COLOR_10_PERCENT = '1A';
export const COLOR_20_PERCENT = '33';
export const COLOR_30_PERCENT = '4D';
export const COLOR_60_PERCENT = '99';
export const COLOR_70_PERCENT = 'B3';
export const COLOR_80_PERCENT = 'CC';

const typography = {
  useNextVariants: true,
  fontFamily: FONT_FAMILY,
};

const themeDark = createMuiTheme({
  palette: dark.material,
  typography,
});

const themeLight = createMuiTheme({
  palette: light.material,
  typography,
});

export const themes = {
  dark: {
    material: themeDark,
    styledComponent: dark.styled(COLOR_30_PERCENT),
  },
  light: {
    material: themeLight,
    styledComponent: light.styled(COLOR_30_PERCENT),
  },
};
