import React from "react";

import styled from "styled-components";

import { AppTranslations } from "../../assets/translations";
import { RankingSkills, PageLanguage } from "../../constants/types";
import OptionSelect from "../Selects/OptionsSelect";
import TransitionEffect from "../TransitionEffect";
import { SubtitleSmall as Title } from "../Typography";

import Rating from "./components/Rating";
import { Container } from "./styles";

type Props = {
  className?: string;
  handleSelectOrderBy: (value?: string) => void;
  isHomePage?: boolean;
  pageLanguage: PageLanguage;
  ranking: RankingSkills;
  selectOderByOption?: string;
};

const RakingView: React.FC<Props> = ({
  isHomePage,
  className = "",
  pageLanguage,
  ranking,
  handleSelectOrderBy,
  selectOderByOption = "",
}) => {
  const { TEXT_RANKING, selectButton } = AppTranslations[pageLanguage].components.ranking;
  const { TEXT_SELECT_ORDER_BY } = AppTranslations[pageLanguage].components.options;

  const classification = isHomePage ? ranking.classification.slice(0, 5) : ranking.classification;

  return (
    <TransitionEffect timeStart={3000}>
      <Container className={className}>
        {isHomePage && <Title id="Container-rating-title">{`${TEXT_RANKING} ( top 5 )`}</Title>}

        {!isHomePage && (
          <SelectionSortBy
            handleSelect={handleSelectOrderBy}
            label={TEXT_SELECT_ORDER_BY}
            options={Object.values(selectButton.options).map((value) => ({ value }))}
            value={selectOderByOption}
          />
        )}

        <ul id="Container-rating">
          {classification.map((tech, i) => (
            <Rating
              key={i}
              maxStars={ranking.starsRange}
              pageLanguage={pageLanguage}
              start={tech.stars}
              projects={tech.projects}
              technologyName={tech.name}
              totalProjects={ranking.totalProjects}
            />
          ))}
        </ul>
      </Container>
    </TransitionEffect>
  );
};

export default RakingView;

const SelectionSortBy = styled(OptionSelect)`
  min-width: 30% !important;
  max-width: 80% !important;
  margin: -3em 0 40px 0 !important;

  p {
    padding: 0 20px 0 0;
  }
`;
