import { css } from 'styled-components';

export const GradientHeight = 20;
export const SpaceForDivider = 2;

const GradientEffectSize = css`
  content: '';
  height: ${GradientHeight}px;
  width: 101%;
  z-index: 100;
  position: absolute;
`;

const colorsForGradient = css`
  --start-color-effect: ${({ theme }) => theme.colors.background.gradientEffectStart};
  --end-color-effect: ${({ theme }) => theme.colors.background.gradientEffectEnd};

  @media (min-width: 1024px) {
    ${({ theme }) =>
      theme.title === 'light'
        ? `
      --start-color-effect: ${theme.colors.background.gradientEffectStartDesktop};
      --end-color-effect: ${theme.colors.background.gradientEffectEndDesktop};`
        : ''};
  }
`;

export const GradientEffect = (elementHeight: number) => css`
  ${colorsForGradient}

  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  &::before {
    ${GradientEffectSize}
    background: linear-gradient(180deg, var(--start-color-effect) -5%, var(--end-color-effect) 100%);
    border-top: ${({ theme }) => theme.colors.background.border} solid 1px;
    margin-top: -${SpaceForDivider}px;
  }
  &::after {
    ${GradientEffectSize}
    background: linear-gradient(0deg, var(--start-color-effect) -5%, var(--end-color-effect) 100%);
    border-bottom: ${({ theme }) => theme.colors.background.border} solid 1px;
    margin-top: ${elementHeight - GradientHeight + SpaceForDivider}px;
  }

  @media (min-width: 1025px) {
    padding-bottom: ${GradientHeight}px !important;
    padding-top: ${GradientHeight}px !important;
    &::before,
    &::after {
      position: absolute;
      margin-top: 0;
      border: 0;
    }

    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }
`;

export const disableGradientEffect = css`
  &::after {
    content: none;
  }
  &::before {
    content: none;
  }
`;
