import React from 'react';

import { LinkButton } from '..';

import { AppTranslations } from '../../assets/translations';
import { PageLanguage } from '../../constants/types';
import { TurnBackIcon } from '../Icons';

type Props = {
  pageLanguage: PageLanguage;
  onClick: () => void;
};

const TurnBackButton: React.FC<Props> = ({ pageLanguage, onClick }) => {
  const { TEXT_BUTTON_TURN_BACK } = AppTranslations[pageLanguage].links;

  return (
    <LinkButton
      style={{ paddingRight: 'revert' }}
      className="Button-turnback"
      iconBefore
      icon={<TurnBackIcon />}
      text={TEXT_BUTTON_TURN_BACK}
      handleClick={onClick}
    />
  );
};

export default TurnBackButton;
