import { AnyAction, Reducer } from "redux";
import { action } from "typesafe-actions";

import { Workplace } from "../../../constants/types";

// TYPES
export const Types = {
  CHANGE: "@workspace/CHANGE_REQUEST",
};

// ACTIONS:
interface Action extends AnyAction {
  payload: Workplace;
}
export const changeWorkplace = (workspace: Workplace) => action(Types.CHANGE, workspace);

const INITIAL_STATE: Workplace = "both";

// REDUCER
const WorkplaceReducer: Reducer<Workplace, Action> = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.CHANGE:
      return action.payload;
    default:
      return state;
  }
};

export default WorkplaceReducer;
