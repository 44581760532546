/* eslint @typescript-eslint/camelcase: 0 */ // --> OFF

import { css } from 'styled-components';

const contentFixedInCenter = css`
  left: 0%;
  top: 50%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  transform: translate(0%, -50%);
`;

const menuFixedInCenterPageHeight = css`
  #Commonpage-aside-drawer .MuiPaper-root {
    margin-top: 8vh;
    height: auto;
    max-height: 83vh;

    ${contentFixedInCenter}

    #Drawer-content {
      overflow-y: auto;
    }
  }
`;

const menuDesktopSmall = css`
  #Commonpage-aside-drawer .MuiPaper-root {
    width: 20%;
    justify-content: center;
    overflow: hidden;
    overflow-y: hidden;

    #Drawer-content {
      align-self: auto;
      width: auto;
      margin-left: 0;
      #Drawer-content-profile {
        width: auto;
        align-items: center;
      }
      #Profile-avatar {
        width: 130px;
        height: 130px;
      }

      #Profile-title {
        font-size: 1.25em;
        text-align: center;
      }
      #Profile-info {
        justify-content: stretch;
        text-align: center;
      }
    }

    #Profile-particulars-position {
      text-align: center;
    }
  }

  @media (max-height: 840px) and (min-width: 1250px) and (max-width: 1600px) {
    #Drawer-content-menu {
      padding: 0% 15%;
    }
  }

  @media (max-width: 1250px), (max-height: 840px) and (max-width: 1600px) {
    #Commonpage-aside-drawer .MuiPaper-root {
      width: 25%;
    }
  }
`;

const menuDesktopFullHeight = css`
  #Commonpage-aside-drawer .MuiPaper-root {
    margin-top: 0vh;
    border-bottom: 0;
    border-top: 0;
    min-height: 100vh;
    height: 100vh;
    max-height: none;
    justify-content: center;
    overflow: hidden;
    overflow-y: hidden;
    top: 50%;

    border-top: none !important;
    border-bottom: none !important;

    #Drawer-content {
      justify-content: center;
      overflow-y: auto;
      display: block;
      height: 100%;

      #Drawer-content-profile {
        margin-top: 5vh;
      }
    }
  }
`;

const menuDesktopSmallFullHeightProfileSmall = css`
  #Commonpage-aside-drawer {
    #Drawer-content {
      overflow-y: auto;
      height: 100%;
      padding-top: 3%;
      padding-bottom: 3%;
      margin: 0% 0%;

      #Profile-info {
        grid-gap: 0.5em;
      }
    }
  }
`;

const buttonsMenuAsideSpace = css`
  #Drawer-toolbar-button-menuopen,
  #Drawer-toolbar-button-menuclose,
  .Menu-nav-buttons-li,
  #Drawer-footer-button-socialmedia {
    padding: 1.5vh;
  }

  #Drawer-toolbar-button-menuopen,
  .Menu-nav-buttons-li,
  #Drawer-footer-button-socialmedia {
    padding-left: 2vw;
  }

  @media (max-width: 500px) {
    #Drawer-toolbar-button-menuopen,
    #Drawer-toolbar-button-menuclose,
    .Menu-nav-buttons-li,
    #Drawer-footer-button-socialmedia {
      padding: 1vh;
    }

    #Drawer-toolbar-button-menuopen,
    .Menu-nav-buttons-li,
    #Drawer-footer-button-socialmedia {
      padding-left: 5vw;
    }
  }
`;

const menuButton = css`
  #Commonpage-aside-drawer .MuiPaper-root {
    overflow-y: hidden;

    ${buttonsMenuAsideSpace}

    #Drawer-content {
      margin: 0vh 0vw;
      overflow-y: auto;
      overflow-x: hidden;

      flex-direction: row-reverse;
      align-self: start;
      width: 100%;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
      border-left: 0;
      margin: 0vh 0vw;

      #Drawer-content-profile {
        width: 100%;
        margin: 5vh 2vw;
        align-items: center;
      }

      #Profile-info {
        #Profile-title {
          font-size: 1.5rem;
        }
        #Profile-title,
        #Profile-particulars-info {
          line-height: 2rem;
        }

        #Profile-particulars-info {
          font-size: 1.3rem;
          .Topics-info {
            font-size: 0.85rem;
          }
          .Global-icon-svg {
            font-size: 1rem;
          }
        }
      }
      #Drawer-content-menu {
        white-space: nowrap;
        .Menu-nav-button-text {
          padding-right: 3vw;
          padding-left: 5px;
        }
      }
    }
  }
`;

// IMPORTANTE CLASSE DEVE REPLICADA - QUEBRA FUNCIONALIDADES PARA DISPOSITIVOS MENORES
const menuButtonsHeight = css`
  margin-top: 0%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
`;

const menuMobileDeviceFullHeight = css`
  #Drawer-menu-open-background {
    top: 0;
    margin-top: 0;
  }
  #Commonpage-aside-drawer .MuiPaper-root {
    ${menuButtonsHeight}
    ${contentFixedInCenter}
    top: 50%;
    border-top: none !important;
    border-bottom: none !important;

    #Drawer-content {
      height: 100%;
      #Drawer-content-menu {
        height: auto;
      }
    }

    #Drawer-toolbar,
    #Drawer-footer {
      min-height: 10vh;
      height: fit-content;
    }

    @media (max-height: 500px) {
      #Drawer-toolbar > span {
        height: auto;
      }
      #Drawer-toolbar,
      #Drawer-footer {
        min-height: auto;
        height: auto;
      }
    }
  }
`;

const drawerToolbarTranslateAndTheme = css`
  #Commonpage-aside-drawer {
    #Drawer-toolbar-languages,
    #Drawer-toolbar-theme {
      display: flex;
    }

    #Drawer-toolbar-languages {
      width: fit-content;
    }
  }
`;

const drawerFooterSocialMedia = css`
  #Commonpage-aside-drawer #Drawer-footer {
    display: flex;
    #Drawer-footer-social-media {
      align-self: center;
      justify-content: center;
      left: 0;
      position: relative;
      a {
        margin: 0% 2%;
      }
    }
  }
`;

const menuMobilePhoneFullScreen = css`
  #Commonpage-aside-drawer .MuiPaper-root {
    border: none !important;
    ${menuButtonsHeight}

    #Drawer-content {
      height: 88%;
      flex-direction: column;
      align-items: center;

      overflow-y: auto;
      overflow-x: hidden;
      min-height: auto;
      justify-content: space-between;

      #Drawer-content-profile {
        width: fit-content;
        margin: 5%;
        min-height: fit-content;
        height: auto;
        justify-content: center;

        #Profile-sidebar-container {
          display: flex;
          flex-direction: column;
          text-align: center;
          width: 100%;
          #Profile-title {
            margin-top: 3vh;
            margin-bottom: 1.5vh;
            font-size: 1.5em;
          }
        }

        #Profile-info {
          margin-top: 1vh;

          #Profile-particulars-info {
            font-size: 1.15rem;
            line-height: 1.7rem;

            .Topics-info {
              font-size: 0.8rem;
            }
            .Global-icon-svg {
              font-size: 0.9rem;
            }
          }

          #Profile-particulars-position {
            margin-bottom: 3%;
          }
        }
      }

      #Drawer-content-menu {
        width: 101%;
        display: grid;
        grid-template-columns: 50% 51%;
        padding-right: 0;
        white-space: normal;

        border-top: solid 1px ${({ theme }) => theme.colors.background.border};

        button {
          word-wrap: break-word;
          white-space: normal;
          padding-right: 0;
          border-right: solid 1px ${({ theme }) => theme.colors.background.border};
          .Menu-nav-button-icon {
            margin-right: 5px;
          }
        }
      }

      .Menu-nav-button-text > span {
        line-height: 1.3rem;
      }

      .Menu-nav-button-text > span {
        font-size: 0.95rem;
      }

      .Menu-nav-button-icon {
        min-width: auto;
        margin-right: 12%;

        svg {
          font-size: 1.4rem;
        }
      }
    }
    #Drawer-footer-button-socialmedia {
      display: none;
    }
  }
`;

const menuMobilePhoneButtonsOnList = css`
  #Commonpage-aside-drawer .MuiPaper-root #Drawer-content {
    #Drawer-content-menu {
      grid-template-columns: repeat(1, 100%);
    }
    #Drawer-content-menu {
      button {
        padding: 1vh 10vw;
      }

      .Menu-nav-button-text > span {
        font-size: 1.1rem !important;
      }
      .Menu-nav-button-icon {
        margin-right: 5vw;
        svg {
          font-size: 1.3rem !important;
        }
      }
    }
  }
`;

const menuMobilePhoneSmallProfile = css`
  #Commonpage-aside-drawer {
    .MuiPaper-root {
      margin-top: 0vh;
      #Drawer-content {
        #Profile-avatar {
          width: 130px;
          height: 130px;
        }
        #Drawer-content-profile {
          margin: 1.5vh 5vw;
        }
        #Profile-title {
          font-size: 1.4rem;
        }
        #Profile-info {
          font-size: 1.1rem;
        }
      }
    }
  }
`;

const menuFullUntilHeaderAndFooter = css`
  #Commonpage-aside-drawer .MuiPaper-root {
    margin-top: 7.7vh;
    ${contentFixedInCenter}
  }

  @media (min-width: 1024px) and (max-width: 1400px) and (max-height: 920px) {
    #Commonpage-aside-drawer .MuiPaper-root {
      overflow-y: auto !important;
      #Drawer-content {
        max-height: inherit;
        padding: 1.8vh;
      }
    }
  }
`;

export const mediaQueries = css`
  @media (min-height: 900px) and (max-height: 1100px) {
    ${menuFullUntilHeaderAndFooter}

    @media (min-width: 1600px) {
      #Commonpage-aside-drawer .MuiPaper-root {
        max-height: 84.4vh;
      }
    }
  }

  @media (max-height: 900px) {
    ${menuFixedInCenterPageHeight}

    @media (max-width: 1024px) {
      #Commonpage-aside-drawer .MuiPaper-root {
        max-height: 80vh;
      }
    }
  }

  @media (min-width: 1025px) and (max-width: 2000px) {
    #Commonpage-aside-drawer .MuiPaper-root {
      border-bottom: none !important;
      border-top: none !important;
      border-color: ${({ theme }) => theme.colors.background.primary} !important;
    }
  }

  @media (min-width: 1025px) and (max-height: 840px) {
    ${menuDesktopFullHeight}

    .Profile-error{
      height: 40% !important;
      margin-top: 10%
    }

    #Commonpage-aside-drawer .MuiPaper-root {
      border-color: ${({ theme }) => theme.colors.background.secondary} !important;
    }
  }

  @media (min-width: 1025px) and (max-width: 1600px) {
    ${menuDesktopSmall}

    @media (max-height: 670px) {
      ${menuDesktopSmallFullHeightProfileSmall}
    }

    @media (min-height: 840px) and (max-height: 900px) {
      ${menuFullUntilHeaderAndFooter}
      ${menuFixedInCenterPageHeight}
      #Drawer-content {
        max-height: inherit;
        padding: 1.8vh;
      }
    }
  }

  @media (max-width: 1024px) {
    ${menuButton}
  }

  /* ACTIVE FULL MENU + SOCIAL MEDIA */
  @media (max-width: 1024px) and (max-height: 700px), (max-width: 500px) {
    ${drawerToolbarTranslateAndTheme}
    ${menuMobileDeviceFullHeight}
    ${drawerFooterSocialMedia}
  }

  @media (min-width: 500px) and (max-width: 1024px) and (max-height: 700px) {
    #Commonpage-aside-drawer #Drawer-content .Profile-error{
      height: auto !important;
      margin-top: 10%
    }
  }

  @media (max-width: 500px) {
    ${menuMobilePhoneFullScreen}

    @media (min-height: 860px), (max-width: 260px) {
      ${menuMobilePhoneButtonsOnList}
    }

    @media (max-height: 740px) {
      ${menuMobilePhoneSmallProfile}
    }
  }

  @media (min-width: 260px) and (max-width: 320px) {
    #Commonpage-aside-drawer .MuiPaper-root #Drawer-content .Menu-nav-button-icon svg {
      font-size: 1.2rem;
    }
    #Commonpage-aside-drawer .MuiPaper-root #Drawer-content .Menu-nav-button-text > span {
      font-size: 0.8rem;
    }
  }
`;
