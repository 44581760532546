import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';

import parse from 'html-react-parser';
import styled from 'styled-components';

import { PageContent as PageContentType } from '../../constants/types';

import ImageViewDialog from './DialogImageView';

type Props = {
  pageContent: Array<PageContentType>;
  replace?: {
    index: number;
    text: string;
    newText: string;
  };
};

const PrintPageContent: React.FC<Props> = ({ pageContent, replace }) => {
  const PageContent = (indexKey: number, content: PageContentType) => {
    if (replace && replace.index === indexKey) {
      if (content.value) content.value = content.value.replace(replace.text, replace.newText);
    }

    switch (content.type) {
      case 'text':
        return (
          <Fragment key={indexKey}>
            <Text>{content.value}</Text>
            <br />
          </Fragment>
        );
      case 'image':
        return (
          <Fragment key={indexKey}>
            <ImageViewDialog key={indexKey} images={[content.value || '']} description={content.description} oneimage />
            <br />
          </Fragment>
        );
      case 'html':
        return <Fragment key={indexKey}>{parse(content.value?.replace('>', ` key={${indexKey}}>`) || '')}</Fragment>;
      case 'title':
        return (
          <Fragment key={indexKey}>
            <TitlePageContent key={indexKey}>{content.value}</TitlePageContent>
            <br />
          </Fragment>
        );
      case 'images_group':
        return (
          <Fragment key={indexKey}>
            <ImageViewDialog key={indexKey} images={content.values} description={content.description} />
            <br />
          </Fragment>
        );
      case 'video':
        return (
          <Fragment key={indexKey}>
            <ReactPlayer style={videoStyle} key={indexKey} url={content.value} />
            <br />
          </Fragment>
        );
      case 'video_giff':
        return (
          <Fragment key={indexKey}>
            <ReactPlayer style={videoStyle} playing muted loop key={indexKey} url={content.value} />
            <br />
          </Fragment>
        );
      default:
        return <Fragment key={indexKey} />;
    }
  };

  return <Fragment>{pageContent.map((content, index) => PageContent(index, content))}</Fragment>;
};

export default PrintPageContent;

const videoStyle = { alignSelf: 'center', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' };

const Text = styled.p`
  text-align: start;
`;

export const TitlePageContent = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.subTitle};
`;
