import React from 'react';

import { PagesTranslations } from '../../../assets/translations';
import { PrintPageContent } from '../../../components';
import { YEARS_AS_SOFTWARE_DEVELOPER } from '../../../constants/constants';
import { PageLanguage } from '../../../constants/types';

type Props = {
  pageLanguage: PageLanguage;
};

const AboutMePage: React.FC<Props> = ({ pageLanguage }) => {
  const pageContent = PagesTranslations[pageLanguage].ABOUT_ME.content;
  return <PrintPageContent pageContent={pageContent} replace={{ index: 1, text: '#YEAR#', newText: YEARS_AS_SOFTWARE_DEVELOPER }} />;
};

export default AboutMePage;
