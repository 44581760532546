import { AnyAction } from "redux";

import { Skill, Workplace } from "../../../constants/types";

/*
 * Action Types
 */
export enum Types {
  LOAD_REQUEST = "@skills/LOAD_REQUEST",
  LOAD_SUCCESS = "@skills/LOAD_SUCCESS",
  LOAD_FAILURE = "@skills/LOAD_FAILURE",
  CHANGE_WORKPLACE = "@skills/CHANGE_WORKPLACE",
}

export type State = {
  readonly data: {
    [key in Workplace]: Array<Skill>;
  };
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  workplace: Workplace;
  data: Array<Skill>;
};

export interface Action extends AnyAction {
  payload: Payload;
}
