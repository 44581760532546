import React from 'react';

import { PageLanguage } from '../../../../constants/types';
import DateUtils from '../../../../Utils/DateUtils';

import SelectedText from './SelectedText';

type Props = { filter: string; pageLanguage: PageLanguage; projectStart: Date; projectEnd: Date };

const Date: React.FC<Props> = (props: Props) => {
  const { filter, pageLanguage, projectStart, projectEnd } = props;
  const fullDate = DateUtils.getFullDateWithTranslationMonths(pageLanguage, projectStart, projectEnd, true);

  if (filter.length === 0) {
    return <span> {DateUtils.getFormattedFullDate(fullDate)}</span>;
  }

  const startYear = fullDate.startDate.getFullYear().toString();
  const finalYear = fullDate.finalDate.getFullYear().toString();

  if (fullDate.startDate.getTime() === fullDate.finalDate.getTime()) {
    const date = `${fullDate.startMonth}/${finalYear}`;
    return <span>{filter === finalYear ? <SelectedText text={date} /> : date}</span>;
  }

  if (fullDate.startDate.getFullYear() === fullDate.finalDate.getFullYear()) {
    const date = `${fullDate.startMonth} - ${fullDate.finalMonth}/${finalYear}`;
    return <span>{filter === finalYear ? <SelectedText text={date} /> : date}</span>;
  }

  const dateStart = `${fullDate.startMonth}/${startYear}`;
  const dateFinal = `${fullDate.finalMonth}/${finalYear}`;

  return (
    <span>
      {filter === startYear ? <SelectedText text={dateStart} /> : dateStart}
      {' - '}
      {filter === finalYear ? <SelectedText text={dateFinal} /> : dateFinal}
    </span>
  );
};

export default Date;
