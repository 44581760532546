const isNullOrEmpty = <T>(array: Array<T | object>) => !(array && array.length > 0);
const isNotNullOrEmpty = <T>(array: Array<T | object>) => !isNullOrEmpty(array);

const getElementsPerPage = <T>(page: number, elements: Array<T>, elementsByPage: number) => {
  if (isNullOrEmpty<T>(elements)) return elements;
  const indexStart = elementsByPage * page - elementsByPage;
  const indexLast = indexStart + elementsByPage;
  return elements.slice(indexStart, indexLast);
};

const getUniqueElements = <T>(array: Array<T>): Array<T> => array.filter((element, index, self) => self.indexOf(element) === index);

const ListUtils = {
  isNullOrEmpty,
  isNotNullOrEmpty,
  getElementsPerPage,
  getUniqueElements,
};

export default ListUtils;
